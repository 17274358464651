import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  TextField,
  isNullOrEmptyField,
} from "utility-library-react16";
import _ from "lodash";
import { ProfileModel } from "../../../Model";
import { useCurrentProfileContext } from "../context/useCurrentProfileContext";

export const Name = (props) => {
  const { label, required, keyfield, placeholderText } = props;
  const [info, setInfo] = useState({ [keyfield]: "" });
  const {
    setData,
    error,
    data,
    activeProfile,
  } = useCurrentProfileContext();

  useEffect(() => {
    if (!activeProfile) {
      setTimeout(() => {
        setInfo({
          [keyfield]: "",
        });
      }, 250);
    }
  }, [activeProfile]);

  useEffect(() => {
    setData({ ...data, ...info });
  }, [info]);

  const placeholder = _.replace(keyfield, /_/g, " ");

  return (
    <>
      <Form.Group
        className="mb-3"
        key={`form-elem-${activeProfile}-${keyfield}`}
      >
        <Form.Label className="mb-1 text-uppercase">
          {label} {required && <span className="text-danger">*</span>}
        </Form.Label>
        <TextField
          id={`${activeProfile}-${keyfield}`}
          type="name"
          className={
            required && error && isNullOrEmptyField(info[keyfield])
              ? "errorControl form-control"
              : "form-control"
          }
          placeholder={placeholderText || `Enter your ${placeholder}`}
          name="First Name"
          defaultValue={info[keyfield]}
          value={info[keyfield]}
          onChangeRetName={(value) => {
            setInfo({ ...info, [keyfield]: value });
          }}
          style={
            required && error && isNullOrEmptyField(info[keyfield])
              ? ProfileModel.customStyle(error, {})
              : {}
          }
        />
      </Form.Group>
    </>
  );
};
