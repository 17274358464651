import { VIDEOVISTINFO } from "../actions/actionTypes";

const INITIAL_STATE = {};

export const videoVisitInfo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIDEOVISTINFO:
      return action.payload;

    default:
      return state;
  }
};
