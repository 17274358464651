import * as actionTypes from "./actionTypes";

export const saveVideoVisitAction = (info) => (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.VIDEOVISTINFO, payload: info });
  dispatchEvent({
    type: actionTypes.CONSTRUCT_FINAL_DATA,
    payload: { appointmentReason: info.reason },
  });
};

export const updateVideoVisitAction = (info) => (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.VIDEOVISTINFO, payload: info });
};
