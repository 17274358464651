import { connect } from "react-redux";
import IntakeForm from "./IntakeForm";
import {
  saveStep1Action,
  saveStep2Action,
  saveStep3Action,
} from "../../../store/actions";

const mapDispatchToProps = (dispatch) => ({
  saveStep1Action: (info) => dispatch(saveStep1Action(info)),
  saveStep2Action: (info) => dispatch(saveStep2Action(info)),
  saveStep3Action: (info) => dispatch(saveStep3Action(info)),
});

const mapStateToProps = (state) => {
  return {
    videoVisitInfo: state.videoVisitInfo,
    paymentStatus: state.paymentStatus.status,
    step1: state.intakeFormInfo.step1,
    step2: state.intakeFormInfo.step2,
    step3: state.intakeFormInfo.step3,
    appointmentInfo: state.appointmentConfirmation.appointmentInfo,
    // isLoading: state?.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntakeForm);
