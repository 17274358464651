import React, { useState } from "react";
import Form from "react-bootstrap/Form";

function Step1({ step1Info, setStep1Info }) {
  const handleClick = (e) => {
    const classList = document.getElementById(e.target.id).classList;
    const text = document.getElementById(e.target.id).innerHTML;
    if (classList.contains("active")) {
      classList.remove("active");
      // setStep1Info((step1Info) =>
      //   step1Info.filter((item) => {
      //     return item !== text;
      //   })
      // );

      let tmpmedicalCondition = [];

      tmpmedicalCondition = step1Info.medicalCondition.filter((item) => {
        tmpmedicalCondition.push(item);
        return item !== text;
      });

      setStep1Info({
        ...step1Info,
        medicalCondition: [...tmpmedicalCondition],
      });
    } else {
      classList.add("active");

      setStep1Info({
        ...step1Info,
        medicalCondition: [
          ...step1Info.medicalCondition,
          document.getElementById(e.target.id).innerHTML,
        ],
      });
    }
  };

  const fetchClassList = (id) => {
    const text = document.querySelector(`#${id}`)?.innerHTML;
    if (step1Info.medicalCondition.includes(text)) {
      document.querySelector(`#${id}`)?.classList.add("active");
    }
  };

  return (
    <>
      <div className="intake-form-1">
        <Form.Label className="mb-1">
          <p className="pt-2">
            <strong>Do you have any underlying medical conditions?</strong>
          </p>
        </Form.Label>
        <div
          id="no_condition"
          className={`question-slot ${fetchClassList("no_condition")}`}
          role="button"
          tabIndex={0}
          onClick={handleClick}
        >
          I do not have any underlying conditions
        </div>
        <div
          id="heart"
          className={`question-slot ${fetchClassList("heart")}`}
          role="button"
          tabIndex={0}
          onClick={handleClick}
        >
          Heart disease
        </div>
        <div
          id="bp"
          className={`question-slot ${fetchClassList("bp")}`}
          role="button"
          tabIndex={0}
          onClick={handleClick}
        >
          High blood pressure
        </div>
        <div
          id="hypertension"
          className={`question-slot ${fetchClassList("hypertension")}`}
          role="button"
          tabIndex={0}
          onClick={handleClick}
        >
          Hypertension
        </div>
        <div
          id="etc"
          className={`question-slot ${fetchClassList("etc")}`}
          role="button"
          tabIndex={0}
          onClick={handleClick}
        >
          etc
        </div>
      </div>
    </>
  );
}

export default Step1;
