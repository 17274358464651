import axios from "axios";
import Progress from "react-progress-2";

let url;
axios.interceptors.request.use(
  (config) => {
    if (config.url.startsWith(process.env.REACT_APP_API_URL)) {
      url = config.url;
      Progress.show();
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    if (response.config.url === url) {
      Progress.hide();
    }
    return Promise.resolve(response);
  },
  (error) => {
    Progress.hide();
    return Promise.reject(error);
  }
);
