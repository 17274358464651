import React, { useEffect, useState } from "react";
import _ from "lodash";
import { CurrentProfileProvider } from "../../Global/context/useCurrentProfileContext";
import { Name, Phone, Email, Gender, DOB } from "../../Global/atoms";
import { ProfileModel } from "../../../Model";

const CurrentProfile = React.memo((props) => {
  const {
    activeProfile,
    submitInvoked,
    setSubmitInvoked,
    submitForm,
    eventKey,
    children,
  } = props;
  const [data, setData] = useState({});
  const [error, setError] = useState(false);
  const [nonMandateError, setNonMandateError] = useState(false);
  const { isInvoked } = submitInvoked;

  useEffect(() => {
    if (!activeProfile) {
      setError(false);
      setNonMandateError(false);
    }
  }, [activeProfile, isInvoked]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const { isInvoked, isSubmitted } = submitInvoked;
      if (isInvoked && activeProfile === eventKey && !isSubmitted) {
        const isInvalid = ProfileModel.checkMandatoryFieldsEmpty(
          parseInt(activeProfile),
          data
        );
        setError(isInvalid);

        const isNonMandatoryInValid = ProfileModel.checkNonMandatoryFieldsEmpty(
          parseInt(activeProfile),
          data
        );
        setNonMandateError(isNonMandatoryInValid);

        if (!isInvalid && !isNonMandatoryInValid) {
          submitForm(data, activeProfile);
        }
        setSubmitInvoked({ ...submitInvoked, isInvoked: false });
      }
    }
  }, [
    submitInvoked,
    data,
    setError,
    setNonMandateError,
    setSubmitInvoked,
    submitForm,
    activeProfile,
    eventKey,
  ]);

  return (
    <CurrentProfileProvider
      value={{
        submitInvoked,
        setSubmitInvoked,
        data,
        setData,
        error,
        nonMandateError,
        setError,
        setNonMandateError,
        submitForm,
        activeProfile,
        eventKey,
      }}
    >
      {children}
    </CurrentProfileProvider>
  );
});

CurrentProfile.Name = Name;
CurrentProfile.Phone = Phone;
CurrentProfile.Email = Email;
CurrentProfile.Gender = Gender;
CurrentProfile.DOB = DOB;

export { CurrentProfile };
