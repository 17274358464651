import * as actionTypes from "./actionTypes";
import axios from "axios";
import _ from "lodash";

export const savePickATimeAction = (info) => async (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.PICKATIME, payload: info });

  if (_.isEmpty(info)) {
    dispatchEvent({
      type: actionTypes.CONSTRUCT_FINAL_INIT,
    });
    dispatchEvent({ type: actionTypes.FETCHSLOT_INIT });
    dispatchEvent({ type: actionTypes.PICKATIME_INIT });
    dispatchEvent({ type: actionTypes.FETCHSTRIPEKEY_INIT });
    dispatchEvent({ type: actionTypes.FETCHSTRIPEKEY_INIT });
    dispatchEvent({ type: actionTypes.CONSTRUCT_FINAL_INIT });
    dispatchEvent({ type: actionTypes.PAYMENT_INIT });
    dispatchEvent({ type: actionTypes.APPOINTMENT_CONFIRM_INIT });
    dispatchEvent({ type: actionTypes.INTAKE_FORM_INIT });
  } else {
    dispatchEvent({
      type: actionTypes.CONSTRUCT_FINAL_DATA,
      payload: {
        appointmentStartTime: info.appointmentStartTime,
        systemEdxProviderAppointmentBookId: info.bookID,
        resourceId: info.userId,
        physician: `${info.lastName}, ${info.firstName}`,
        systemEdxProviderId: info.systemEdxProviderId,
      },
    });
  }
};

export const saveProfileInformationAction = (info) => (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.PROFILE, payload: info });
  dispatchEvent({
    type: actionTypes.CONSTRUCT_FINAL_DATA,
    payload: {
      patient: { ...info.patient },
      attendee: { ...info.attendee },
      relationWithPatient: info.relationWithPatient,
    },
  });
};

export const getAvailabilitySlots = (info) => async (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.FETCHSLOT_INIT });
  await axios
    .get(`${process.env.REACT_APP_API_URL}/users/systemedx/availability`, {
      params: { ...info },
    })
    .then((res) =>
      dispatchEvent({
        type: actionTypes.FETCHSLOT_SUCCESS,
        payload: res.data,
      })
    )
    .catch((err) => {
      dispatchEvent({
        type: actionTypes.FETCHSLOT_ERROR,
        error: err?.response?.data?.message,
      });

      setTimeout(() => {
        dispatchEvent({
          type: actionTypes.FETCHSLOT_ERROR_RESET,
          error: err?.response?.data?.message || "Something went wrong!!",
        });
      }, 2000);
    });
};
