import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import AccordionList from "./AccordionList";
import AccordionRow from "./AccordionRow";
import { ProfileModel } from "../../../Model";

const Profile = (props) => {
  const { activeStep, steps, handleNext, saveProfileInformationAction } = props;
  const [submitInvoked, setSubmitInvoked] = useState({
    isInvoked: false,
    isValid: false,
    isSubmitted: false,
  });
  const [activeProfileSelected, setActiveProfileSelected] = useState();

  const submitForm = (data, activeProfile) => {
    setSubmitInvoked({ ...submitInvoked, isSubmitted: true });
    const finalData = ProfileModel.segregateDataOnProfile(
      data,
      parseInt(activeProfile)
    );
    saveProfileInformationAction({ ...finalData });
    handleNext();
  };

  const handleOnNext = () => {
    setSubmitInvoked({ ...submitInvoked, isInvoked: true });
  };

  const activeProfileCallback = (info) => {
    setActiveProfileSelected(info);
  };

  return (
    <React.Fragment>
      <div className="card-shadow custom-card pick-time p-4 rounded-3">
        <h3 className="mb-5">Profile Information</h3>

        <Row className="justify-content-center mt-4">
          <Col md={6} sm={12}>
            <Accordion>
              {/* <Accordion > */}
              <p className="bolder-text">Who is this for?</p>
              <AccordionList activeProfileCallback={activeProfileCallback}>
                <AccordionRow
                  label={ProfileModel.profileLabel[0]}
                  submitInvoked={submitInvoked}
                  setSubmitInvoked={setSubmitInvoked}
                  submitForm={submitForm}
                />
                <AccordionRow
                  label={ProfileModel.profileLabel[1]}
                  submitInvoked={submitInvoked}
                  setSubmitInvoked={setSubmitInvoked}
                  submitForm={submitForm}
                />
                <AccordionRow
                  label={ProfileModel.profileLabel[2]}
                  submitInvoked={submitInvoked}
                  setSubmitInvoked={setSubmitInvoked}
                  submitForm={submitForm}
                />
              </AccordionList>
            </Accordion>
          </Col>
        </Row>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
          {/* <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                        Back
                    </Button> */}
          <Box sx={{ flex: "1 1 auto" }} />
          {/* {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                            Skip
                        </Button>
                    )} */}

          {activeProfileSelected && (
            <Button onClick={handleOnNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          )}
        </Box>
      </div>
    </React.Fragment>
  );
};

export default Profile;
