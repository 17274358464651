import { connect } from "react-redux";
import Payment from "./Payment";
import {
  getPaymentGatewayKey,
  setPaymentStatus,
  setAppointmentStatus,
} from "../../../store/actions";

const mapDispatchToProps = (dispatch) => ({
  getPaymentGatewayKey: () => dispatch(getPaymentGatewayKey()),
  setPaymentStatus: (info) => dispatch(setPaymentStatus(info)),
  setAppointmentStatus: (info) => dispatch(setAppointmentStatus(info)),
});

const mapStateToProps = (state) => {
  return {
    isLoading: state?.paymentInfo.isLoading,
    error: state.paymentInfo.error,
    stripeKey: state.paymentInfo.data?.stripe_key,
    postInfo: state.paymentPostInfo,
    zipPatWiz: state.videoVisitInfo.zip,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
