import {
  FETCHSTRIPEKEY_INIT,
  FETCHSTRIPEKEY_ERROR,
  FETCHSTRIPEKEY_SUCCESS,
  CONSTRUCT_FINAL_DATA,
  PAYMENT_METHOD_ID,
  CONSTRUCT_FINAL_INIT,
  PAYMENT_SUCCESS,
  PAYMENT_INIT,
  APPOINTMENT_SUCCESS,
  APPOINTMENT_CONFIRM_INIT,
} from "../actions/actionTypes";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  data: null,
};

export const paymentInfo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHSTRIPEKEY_INIT:
      return { ...state, isLoading: true, data: null, error: null };
    case FETCHSTRIPEKEY_SUCCESS:
      return { ...state, isLoading: false, data: action.payload, error: null };
    case FETCHSTRIPEKEY_ERROR:
      return { ...state, isLoading: false, data: null, error: action.error };

    default:
      return state;
  }
};

const PAYMENT_STATE = {
  patient: {},
  relationWithPatient: "",
  attendee: {},
  appointmentStartTime: "",
  appointmentReason: "",
  resourceId: "",
  physician: "",
  systemEdxProviderId: "",
  systemEdxProviderAppointmentBookId: "",
  paymentMethodId: "",
};

export const paymentPostInfo = (state = PAYMENT_STATE, action) => {
  switch (action.type) {
    case CONSTRUCT_FINAL_INIT: {
      return { ...PAYMENT_STATE };
    }
    case CONSTRUCT_FINAL_DATA:
      return { ...state, ...action.payload };
    case PAYMENT_METHOD_ID:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const PAYMENT_STATUS = {
  status: null,
};

export const paymentStatus = (state = PAYMENT_STATUS, action) => {
  switch (action.type) {
    case PAYMENT_SUCCESS: {
      return { status: action.payload };
    }
    case PAYMENT_INIT: {
      return { status: null };
    }
    default:
      return state;
  }
};

const APPOINTMENT_CONFIRM_STATE = {
  appointmentInfo: null,
};
export const appointmentConfirmation = (
  state = APPOINTMENT_CONFIRM_STATE,
  action
) => {
  switch (action.type) {
    case APPOINTMENT_SUCCESS: {
      return { appointmentInfo: action.payload };
    }
    case APPOINTMENT_CONFIRM_INIT: {
      return { appointmentInfo: null };
    }
    default:
      return state;
  }
};
