import { connect } from "react-redux";
import ReviewAppointment from "./ReviewAppointment";

const mapDispatchToProps = (dispatch) => ({
  // savePickATimeAction: (info) => dispatch(savePickATimeAction(info)),
});

const mapStateToProps = (state) => {
  return {
    pickATimeInfo: state.patientWizardInfo.pickATime,
    // isLoading: state?.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewAppointment);
