import { connect } from "react-redux";
import UrgentCare from "./UrgentCare";
import {
  saveVideoVisitAction,
  savePickATimeAction,
} from "../../../store/actions";

const mapDispatchToProps = (dispatch) => ({
  //   FetchHomePageHeaderInfo: () => FetchHomePageHeaderInfo(dispatch),
  saveVideoVisitAction: (info) => dispatch(saveVideoVisitAction(info)),
  savePickATimeAction: (info) => dispatch(savePickATimeAction(info)),
});

const mapStateToProps = (state) => {
  return {
    // isLoading: state?.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UrgentCare);
