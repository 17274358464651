import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { isNullOrEmptyField } from "utility-library-react16";
import { useCurrentProfileContext } from "../context/useCurrentProfileContext";

export const Gender = (props) => {
  const { required, keyfield } = props;
  const [info, setInfo] = useState({ [keyfield]: "" });
  const { setData, error, data, activeProfile } = useCurrentProfileContext();

  useEffect(() => {
    if (!activeProfile) {
      setTimeout(() => {
        setInfo({
          [keyfield]: "",
        });
      }, 250);
    }
  }, [activeProfile]);

  useEffect(() => {
    setData({ ...data, ...info });
  }, [info]);

  return (
    <>
      <Form.Group
        className="mb-3"
        key={`form-elem-${activeProfile}-${keyfield}`}
      >
        <Form.Label
          className={
            error && isNullOrEmptyField(info[keyfield])
              ? "text-danger mb-1 text-uppercase w-100"
              : "mb-1 text-uppercase w-100"
          }
        >
          Gender {required && <span className="text-danger">*</span>}
        </Form.Label>
        <Form.Check
          inline
          label="Female"
          value="female"
          name={keyfield}
          type="radio"
          id="Female-myself"
          checked={info[keyfield] === "female"}
          onChange={(e) => setInfo({ ...info, [keyfield]: e.target.value })}
        />
        <Form.Check
          inline
          label="Male"
          value="male"
          name={keyfield}
          type="radio"
          id="Male-myself"
          checked={info[keyfield] === "male"}
          onChange={(e) => setInfo({ ...info, [keyfield]: e.target.value })}
        />
        <Form.Check
          inline
          label="Other"
          value="other"
          name={keyfield}
          type="radio"
          id="other-myself"
          checked={info[keyfield] === "other"}
          onChange={(e) => setInfo({ ...info, [keyfield]: e.target.value })}
        />
      </Form.Group>
    </>
  );
};
