import _ from "lodash";
import { isValidEmail } from "utility-library-react16";
import moment from "moment-timezone";
import { cleanseData } from "utility-library-react16";

const profileLabel = ["Myself", "My Child", "Someone Else"];

export const styledComponentStyle = (activeProfile, index) => {
  const translation = activeProfile === "0" ? 0 : activeProfile === "1" ? 3 : 6;

  const style = activeProfile
    ? activeProfile !== index
      ? {
          transition: "all 1.0s",
          opacity: 0,
          transform: "translateY(0px)",
        }
      : {
          transition: "all 1.0s",
          opacity: 1,
          transform: `translateY(-${translation}rem)`,
        }
    : {
        transition: "all 1.0s",
        opacity: 1,
      };
  return style;
};

const mandatoryFieldsList = [
  ["first_name", "last_name", "phone", "gender", "dob"],
  [
    "child_first_name",
    "child_last_name",
    "child_gender",
    "child_dob",
    "self_first_name",
    "self_last_name",
    "self_phone",
  ],
  [
    "pat_first_name",
    "pat_last_name",
    "pat_gender",
    "pat_dob",
    "pat_phone",
    "self_first_name",
    "self_last_name",
    "self_phone",
    "self_gender",
    "pat_relationship",
  ],
];

const NonmandatoryFieldsList = [
  ["email"],
  ["self_email"],
  ["pat_email", "self_email"],
];

const checkMandatoryFieldsEmpty = (index, data) => {
  if (_.isEmpty(data)) {
    return true;
  }
  const isInvalid = mandatoryFieldsList[index].some((key) => {
    if (data[key] === "") {
      return true;
    }
    return false;
  });

  return isInvalid;
};

const checkNonMandatoryFieldsEmpty = (index, data) => {
  const isInvalid = NonmandatoryFieldsList[index].some((key) => {
    if (data[key] === "") {
      return false;
    }
    if (key.endsWith("email") || key.startsWith("email")) {
      return !isValidEmail({ str: data[key], isNullable: true });
    }
    if (key === "phone") {
      return data[key]?.length < 13;
    }
    return false;
  });

  return isInvalid;
};

const customStyle = (error, passedstyle) => {
  const style = error
    ? { border: "1px solid red", ...passedstyle }
    : { ...passedstyle };
  return style;
};

const cFields = {
  firstName: "",
  lastName: "",
  phone: "",
  gender: "",
  email: "",
};
const segregateDataOnProfile = (data, activeStep) => {
  let finalData = {
    patient: {
      ...cFields,
      dob: "",
      zipCode: "",
    },
    relationWithPatient:
      activeStep === 1
        ? "child"
        : activeStep === 2
        ? data["pat_relationship"]
        : "",
    attendee: {
      ...cFields,
    },
  };

  if (activeStep === 0) {
    const cleanPhone = cleanseData(
      data[mandatoryFieldsList[parseInt(activeStep)][2]]
    );

    finalData = {
      ...finalData,
      patient: {
        firstName: data[mandatoryFieldsList[parseInt(activeStep)][0]],
        lastName: data[mandatoryFieldsList[parseInt(activeStep)][1]],
        phone: cleanPhone,
        gender: data[mandatoryFieldsList[parseInt(activeStep)][3]],
        dob: moment(data[mandatoryFieldsList[parseInt(activeStep)][4]]).format(
          "MM/DD/YYYY"
        ),
        email: data[NonmandatoryFieldsList[parseInt(activeStep)][0]],
      },
    };
  }
  if (activeStep === 1) {
    const cleanPhone = cleanseData(
      data[mandatoryFieldsList[parseInt(activeStep)][6]]
    );

    finalData = {
      ...finalData,
      patient: {
        firstName: data[mandatoryFieldsList[parseInt(activeStep)][0]],
        lastName: data[mandatoryFieldsList[parseInt(activeStep)][1]],
        gender: data[mandatoryFieldsList[parseInt(activeStep)][2]],
        dob: moment(data[mandatoryFieldsList[parseInt(activeStep)][3]]).format(
          "MM/DD/YYYY"
        ),
        phone: cleanPhone,
        email: data[NonmandatoryFieldsList[parseInt(activeStep)][0]],
      },
      attendee: {
        firstName: data[mandatoryFieldsList[parseInt(activeStep)][4]],
        lastName: data[mandatoryFieldsList[parseInt(activeStep)][5]],
        phone: cleanPhone,
        email: data[NonmandatoryFieldsList[parseInt(activeStep)][0]],
      },
    };
  }
  if (activeStep === 2) {
    const cleanPhone1 = cleanseData(
      data[mandatoryFieldsList[parseInt(activeStep)][4]]
    );

    const cleanPhone2 = cleanseData(
      data[mandatoryFieldsList[parseInt(activeStep)][7]]
    );

    finalData = {
      ...finalData,
      patient: {
        firstName: data[mandatoryFieldsList[parseInt(activeStep)][0]],
        lastName: data[mandatoryFieldsList[parseInt(activeStep)][1]],
        gender: data[mandatoryFieldsList[parseInt(activeStep)][2]],
        dob: moment(data[mandatoryFieldsList[parseInt(activeStep)][3]]).format(
          "MM/DD/YYYY"
        ),

        phone: cleanPhone1,
        email: data[NonmandatoryFieldsList[parseInt(activeStep)][0]],
      },
      attendee: {
        firstName: data[mandatoryFieldsList[parseInt(activeStep)][5]],
        lastName: data[mandatoryFieldsList[parseInt(activeStep)][6]],
        phone: cleanPhone2,
        email: data[NonmandatoryFieldsList[parseInt(activeStep)][1]],
        gender: data[mandatoryFieldsList[parseInt(activeStep)][8]],
      },
    };
  }

  return finalData;
};

export const ProfileModel = {
  profileLabel,
  styledComponentStyle,
  mandatoryFieldsList,
  checkMandatoryFieldsEmpty,
  checkNonMandatoryFieldsEmpty,
  customStyle,
  segregateDataOnProfile,
};
