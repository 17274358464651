import { connect } from "react-redux";
import Confirmation from "./Confirmation";

const mapDispatchToProps = (dispatch) => ({
  //   FetchHomePageHeaderInfo: () => FetchHomePageHeaderInfo(dispatch),
  //   saveVideoVisitAction: (info) => dispatch(saveVideoVisitAction(info)),
});

const mapStateToProps = (state) => {
  return {
    pickATimeInfo: state.patientWizardInfo.pickATime,
    // isLoading: state?.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
