import { connect } from "react-redux";
import PickATime from "./PickATime";
import {
  savePickATimeAction,
  getAvailabilitySlots,
} from "../../../store/actions";

const mapDispatchToProps = (dispatch) => ({
  savePickATimeAction: (info) => dispatch(savePickATimeAction(info)),
  getAvailabilitySlots: (info) => dispatch(getAvailabilitySlots(info)),
});

const mapStateToProps = (state) => {
  return {
    pickATimeInfo: state.patientWizardInfo.pickATime,
    videoVisitInfo: state.videoVisitInfo,
    slotAvailabilityInfo: state.slotAvailabilityInfo.data,
    isLoading: state.slotAvailabilityInfo.isLoading,
    isError: state.slotAvailabilityInfo.error
    // isLoading: state?.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PickATime);
