export const IS_LOADING = "IS_LOADING";
export const COMMON_SET_USERINFO = "COMMON_SET_USERINFO";
export const CLEAR_DATA = "CLEAR_DATA";
export const VIDEOVISTINFO = "VIDEOVISTINFO";
export const VIDEOVISTINFOINIT = "VIDEOVISTINFOINIT";
export const PICKATIME = "PICKATIME";
export const PICKATIME_INIT = "PICKATIME_INIT";
export const PROFILE = "PROFILE";
export const FETCHSTRIPEKEY_INIT = "FETCHSTRIPEKEY_INIT";
export const FETCHSTRIPEKEY_SUCCESS = "FETCHSTRIPEKEY_SUCCESS";
export const FETCHSTRIPEKEY_ERROR = "FETCHSTRIPEKEY_ERROR";
export const FETCHSLOT_INIT = "FETCHSLOT_INIT";
export const FETCHSLOT_SUCCESS = "FETCHSLOT_SUCCESS";
export const FETCHSLOT_ERROR = "FETCHSLOT_ERROR";
export const FETCHSLOT_ERROR_RESET = "FETCHSLOT_ERROR_RESET";
export const CONSTRUCT_FINAL_DATA = "CONSTRUCT_FINAL_DATA";
export const PAYMENT_METHOD_ID = "PAYMENT_METHOD_ID";
export const CONSTRUCT_FINAL_INIT = "CONSTRUCT_FINAL_INIT";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_INIT = "PAYMENT_INIT";
export const INTAKE_FORM_STEP1 = "INTAKE_FORM_STEP1";
export const INTAKE_FORM_STEP2 = "INTAKE_FORM_STEP2";
export const INTAKE_FORM_STEP3 = "INTAKE_FORM_STEP3";
export const INTAKE_FORM_INIT = "INTAKE_FORM_INIT";
export const APPOINTMENT_SUCCESS = "APPOINTMENT_SUCCESS";
export const APPOINTMENT_CONFIRM_INIT = "APPOINTMENT_CONFIRM_INIT";
