import React, { cloneElement, useState, useEffect } from "react";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { useContext } from "react";
import AccordionChild from "./AccordionChild";
import { editIcon } from "../../../Asset/images";
import { ProfileModel } from "../../../Model";

const PINK = "#0D5183";
const BLUE = "#fff";
const BLUEtext = "#0D5183";
const Pinktext = "#fff";

function ContextAwareToggle({ children, eventKey, callback }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;
  let isDisabled = false;
  if (activeEventKey >= 0 && activeEventKey !== eventKey) {
    isDisabled = true;
  }
  if (!activeEventKey) {
    isDisabled = false;
  }

  return (
    <button
      type="button"
      className="accordionBtn"
      style={{
        backgroundColor: isCurrentEventKey ? PINK : BLUE,
        color: isCurrentEventKey ? Pinktext : BLUEtext,
      }}
      onClick={decoratedOnClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}

const AccordionRow = (props) => {
  const {
    activeProfile,
    setActiveProfile,
    index,
    label,
    submitInvoked,
    setSubmitInvoked,
    submitForm,
  } = props;

  const onClickAccordion = (info) => {
    setActiveProfile((prevProps) => {
      if (!prevProps) {
        return info;
      }
      if (prevProps === info) {
        return null;
      }
      if (prevProps !== info) {
        return info;
      }
    });
  };

  return (
    // <div hidden={activeProfile && activeProfile !== index} id={label}>
    <div
      id={label}
      style={ProfileModel.styledComponentStyle(activeProfile, index)}
    >
      <div className="profile-information-btn-holder">
        <ContextAwareToggle
          eventKey={index}
          className="profile-info-btn"
          callback={onClickAccordion}
        >
          {label}
          {activeProfile === index && (
            <span>
              <img src={editIcon} alt="" className="ml-1" />
            </span>
          )}
        </ContextAwareToggle>
      </div>

      {cloneElement(<AccordionChild />, {
        eventKey: props.index,
        label: label,
        submitInvoked,
        setSubmitInvoked,
        activeProfile,
        submitForm,
      })}
    </div>
  );
};

export default AccordionRow;
