import * as actionTypes from "./actionTypes";
import axios from "axios";

export const getPaymentGatewayKey = () => async (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.FETCHSTRIPEKEY_INIT });
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/payment/details`
  );

  if (res.status === 200) {
    dispatchEvent({
      type: actionTypes.FETCHSTRIPEKEY_SUCCESS,
      payload: res.data,
    });
  } else {
    dispatchEvent({
      type: actionTypes.FETCHSTRIPEKEY_ERROR,
      error: res.message,
    });
  }
};

export const setPaymentStatus = (info) => async (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.PAYMENT_SUCCESS, payload: info });
};

export const setAppointmentStatus = (info) => async (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.APPOINTMENT_SUCCESS, payload: info });
};
