import { connect } from "react-redux";
import PatientWizard from "./PatientWizard";
import { saveVideoVisitAction } from "../../../store/actions";

const mapDispatchToProps = (dispatch) => ({
  saveVideoVisitAction: (info) => dispatch(saveVideoVisitAction(info)),
});

const mapStateToProps = (state) => {
  return {
    videoVisitInfo: state.videoVisitInfo,
    paymentStatus: state.paymentStatus.status,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientWizard);
