import {
    FETCHSLOT_INIT,
    FETCHSLOT_SUCCESS,
    FETCHSLOT_ERROR,
    FETCHSLOT_ERROR_RESET
} from "../actions/actionTypes";
import { DAY_AFTER_TOMORROW } from "../../Model/constants";
import _ from "lodash";

const INITIAL_STATE = {
    isLoading: false,
    error: null,
    data: null,
};

export const slotAvailabilityInfo = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCHSLOT_INIT:
            return { ...state, isLoading: true, data: null, error: null };
        case FETCHSLOT_SUCCESS: {

            let info = {};
            if (!_.isEmpty(action.payload)) {
                info = { ...action.payload };
                const key = DAY_AFTER_TOMORROW;
                if (key in info) {
                    info = { ...info, dayAfterTomorrow: { ...info[key] } };
                    info = _.omit(info, DAY_AFTER_TOMORROW);
                }
            }
            return { ...state, isLoading: false, data: info, error: null };
        }
        case FETCHSLOT_ERROR:
            return { ...state, isLoading: false, data: null, error: action.error };

        case FETCHSLOT_ERROR_RESET:
            return { ...state, isLoading: false, data: null, error: null };
        default:
            return state;
    }
};
