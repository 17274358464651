import {
  encryptToken,
  decryptData,
} from "../Components/PageContainer/Payment/helper";

// {
//   "patientId": "12",
//   "appointmentId": "122",
//   "formData": {
//    "q1_medicalCondition":
//       [
//         {
//         "question": "Do you have any underlying medical conditions?",
//         "answer": "Heart disease"
//         }
//       ],
//     "q2_medication":
//       [
//         {
//           "question": "Are you taking any medication?",
//           "answer": "Yes"
//         }
//       ],
//     "q3_currentlyTakingMedication":
//       [
//         {
//           "question": "Are you currently taking any medication?",
//           "answer": "Yes"
//         },
//         {
//           "question": "Medication Name(s)",
//           "answer": "Test medication"
//         }
//       ],
//       "q4_allergy":
//       [
//         {
//           "question": "Do you have any of the following allergy?",
//           "answer": "Food"
//         }
//       ]
//   }
// }

const withoutNull = (formData) => {
  let tmpFormData = [];
  Object.values(formData).forEach((item) => {
    const tmpSubItem = item.filter((subItem) => {
      return subItem !== null;
    });
    tmpFormData.push([...tmpSubItem]);
  });

  return tmpFormData;
};

const constructRequestParams = async (info) => {
  const { step1, step2, step3, patientId, appointmentId } = info;
  console.log(info);

  let formData = {
    q1_medicalCondition: [
      {
        question: "Do you have any underlying medical conditions?",
        answer: step1?.medicalCondition?.join(", "),
      },
    ],
    q2_currentMedication: [
      {
        question: "Are you currently taking any medication?",
        answer: step2?.medication?.join(", "),
      },

      step2.yes
        ? {
            question: "Medication Name (s)",
            answer: step2?.yes,
          }
        : null,
    ],
    q3_allergy: [
      {
        question: "Do you have any of the following allergy?",
        answer: step3?.allergy?.join(", "),
      },
      step3?.medicationAllergy
        ? {
            question: "Medication Allergy",
            answer: step3?.medicationAllergy,
          }
        : null,
      step3?.otherAllergy
        ? {
            question: "Other Allergy",
            answer: step3?.otherAllergy,
          }
        : null,
    ],
  };

  const formWithoutNullEntries = withoutNull(formData);

  const finalData = {
    patientId,
    appointmentId,
    formData: { ...formWithoutNullEntries },
  };

  const encryptedData = encryptToken(JSON.stringify(finalData));
  // const decryptedData = await decryptData(encryptedData);
  console.log("encrypted form Data  => ", encryptedData);
};
export const IntakeFormModel = {
  constructRequestParams,
};
