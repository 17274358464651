import * as actionTypes from "./actionTypes";

export const saveStep1Action = (info) => (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.INTAKE_FORM_STEP1, payload: info });
};

export const saveStep2Action = (info) => (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.INTAKE_FORM_STEP2, payload: info });
};

export const saveStep3Action = (info) => (dispatchEvent) => {
  dispatchEvent({ type: actionTypes.INTAKE_FORM_STEP3, payload: info });
};
