import React, { useEffect, useState } from "react";
import { TextField } from "utility-library-react16";

export const Name2 = (props) => {
  const { keyfield, placeholderText, onChange, value } = props;
  const [info, setInfo] = useState({ [keyfield]: "" });

  useEffect(() => {
    onChange(info[keyfield]);
  }, [info]);

  return (
    <>
      <TextField
        key={keyfield}
        type="titleNotes"
        className={"form-control"}
        placeholder={placeholderText}
        fieldKey={keyfield}
        value={info[keyfield] || value}
        defaultValue={info[keyfield] || value}
        data={info}
        setData={setInfo}
      />
    </>
  );
};
