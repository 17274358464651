const paymentInfoZip = { key: "zip" };

const primaryStyle = {
  // iconColor: "#c4f0ff",
  // fontSize: "12rem",
  letterSpacing: "0.025em",
  color: `rgba(0, 0, 0, 0.85)`,
  fontWeight: "400",
  fontFamily: 'Source Sans Pro", sans-serif',
  fontSmoothing: "antialiased",
  "::placeholder": {
    color: "#A3A3A3",
    fontSize: "1.05rem",
  },
};

const errorStyle = {
  color: "red",
  iconColor: "red",
  borderColor: `red`,
};

export const PaymentModel = {
  paymentInfoZip,
  errorStyle,
  primaryStyle,
};
