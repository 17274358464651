import moment from "moment-timezone";
import { CST_TIMEZONE } from "./constants";

const pickATimeMap = {
    today: "today",
    asap: "today",
    tomorrow: "tomorrow",
    dayAfterTomorrow: "dayAfterTomorrow"
};

const tabLabel = {
    today: "Today",
    tomorrow: moment().tz(CST_TIMEZONE).add(1, "days").format("llll").split(",").splice(0, 2).join(","),
    dayAfterTomorrow: moment().tz(CST_TIMEZONE).add(2, "days").format("llll").split(",").splice(0, 2).join(",")
};

const tabLabel2 = {
    today: moment().tz(CST_TIMEZONE).format("DD/MM/YYYY").split(",").splice(0, 2).join(","),
    tomorrow: moment().tz(CST_TIMEZONE).add(1, "days").format("DD/MM/YYYY").split(",").splice(0, 2).join(","),
    dayAfterTomorrow: moment().tz(CST_TIMEZONE).add(2, "days").format("DD/MM/YYYY").split(",").splice(0, 2).join(",")
};

const duration = {
    today: { toDate: moment().tz(CST_TIMEZONE).format("YYYY-MM-DD"), fromDate: moment().tz(CST_TIMEZONE).format("YYYY-MM-DD") },
    tomorrow: {
        toDate: moment().tz(CST_TIMEZONE).add(1, "days").format("YYYY-MM-DD"),
        fromDate: moment().tz(CST_TIMEZONE).add(1, "days").format("YYYY-MM-DD")
    },
    dayAfterTomorrow: {
        toDate: moment().tz(CST_TIMEZONE).add(2, "days").format("YYYY-MM-DD"),
        fromDate: moment().tz(CST_TIMEZONE).add(2, "days").format("YYYY-MM-DD")
    }
};

export const PickATimeModel = {
    pickATimeMap,
    tabLabel,
    duration,
    tabLabel2
};
