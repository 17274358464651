import React from "react";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import { CurrentProfile } from "./CurrentProfile";

const AccordionChild = ({
  eventKey,
  label,
  submitInvoked,
  setSubmitInvoked,
  activeProfile,
  submitForm,
}) => {
  return (
    <>
      <Accordion.Collapse eventKey={eventKey} id={label}>
        <div>
          {eventKey === "0" && (
            <CurrentProfile
              activeProfile={activeProfile}
              submitInvoked={submitInvoked}
              setSubmitInvoked={setSubmitInvoked}
              submitForm={submitForm}
              eventKey={eventKey}
            >
              <Form className="profile-form" id="0">
                <CurrentProfile.Name
                  label="First Name"
                  required
                  keyfield="first_name"
                />
                <CurrentProfile.Name
                  label="Last Name"
                  required
                  keyfield="last_name"
                />
                <CurrentProfile.Phone required keyfield="phone" />
                <CurrentProfile.Email keyfield="email" />
                <CurrentProfile.Gender required keyfield="gender" />
                <CurrentProfile.DOB
                  required
                  keyfield="dob"
                  placeholderText="Enter your date of birth"
                />
              </Form>
            </CurrentProfile>
          )}

          {eventKey === "1" && (
            <CurrentProfile
              activeProfile={activeProfile}
              submitInvoked={submitInvoked}
              setSubmitInvoked={setSubmitInvoked}
              submitForm={submitForm}
              eventKey={eventKey}
            >
              <Form className="profile-form" id="1">
                <p className="bolder-text mt-4 mb-2">
                  Tell us about your child
                </p>
                <CurrentProfile.Name
                  label="First Name"
                  required
                  keyfield="child_first_name"
                  placeholderText="Enter your child's first name"
                />
                <CurrentProfile.Name
                  label="Last Name"
                  required
                  keyfield="child_last_name"
                  placeholderText="Enter your child's last name"
                />
                <CurrentProfile.Gender required keyfield="child_gender" />
                <CurrentProfile.DOB
                  required
                  keyfield="child_dob"
                  placeholderText="Enter your child's date of birth"
                />

                <p className="bolder-text mt-4 mb-2">Tell us about yourself</p>

                <CurrentProfile.Name
                  label="First Name"
                  required
                  keyfield="self_first_name"
                  placeholderText="Enter your first name"
                />
                <CurrentProfile.Name
                  label="Last Name"
                  required
                  keyfield="self_last_name"
                  placeholderText="Enter your last name"
                />
                <CurrentProfile.Phone required keyfield="self_phone" />
                <CurrentProfile.Email
                  keyfield="self_email"
                  placeholderText="Enter your email"
                />
              </Form>
            </CurrentProfile>
          )}

          {eventKey === "2" && (
            <CurrentProfile
              activeProfile={activeProfile}
              submitInvoked={submitInvoked}
              setSubmitInvoked={setSubmitInvoked}
              submitForm={submitForm}
              eventKey={eventKey}
            >
              <Form className="profile-form" id="2">
                <p className="bolder-text mt-4 mb-2">
                  Your relationship to the patient
                </p>
                <CurrentProfile.Name
                  required
                  label="Relationship"
                  placeholderText="Enter your relationship to the patient"
                  keyfield="pat_relationship"
                />
                <p className="bolder-text mt-4 mb-2">
                  Tell us about the patient
                </p>
                <CurrentProfile.Name
                  label="First Name"
                  required
                  keyfield="pat_first_name"
                  placeholderText="Enter patient's first name"
                />
                <CurrentProfile.Name
                  label="Last Name"
                  required
                  keyfield="pat_last_name"
                  placeholderText="Enter patient's last name"
                />
                <CurrentProfile.Phone required keyfield="pat_phone" />
                <CurrentProfile.Email
                  keyfield="pat_email"
                  placeholderText="Enter patient's email"
                />
                <CurrentProfile.Gender required keyfield="pat_gender" />
                <CurrentProfile.DOB
                  required
                  keyfield="pat_dob"
                  placeholderText="Enter patient's date of birth"
                />

                <p className="bolder-text mt-4 mb-2">Tell us about yourself</p>
                <CurrentProfile.Name
                  label="First Name"
                  required
                  keyfield="self_first_name"
                  placeholderText="Enter your first name"
                />
                <CurrentProfile.Name
                  label="Last Name"
                  required
                  keyfield="self_last_name"
                  placeholderText="Enter your last name"
                />
                <CurrentProfile.Phone required keyfield="self_phone" />
                <CurrentProfile.Email
                  keyfield="self_email"
                  placeholderText="Enter your email"
                />
                <CurrentProfile.Gender required keyfield="self_gender" />
              </Form>
            </CurrentProfile>
          )}
        </div>
      </Accordion.Collapse>
    </>
  );
};

export default AccordionChild;
