import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Loader from "react-js-loader";
// import { doctorPng } from "../../../Asset/images";
import { PickATimeModel } from "../../../Model/PickATime";
import pickATimeJSON from "./pickATime.json";
import { alertService } from "../../../data/services";
import AccessTimeOutlined from "@material-ui/icons/AccessTimeOutlined";
import moment from "moment-timezone";
import moment1 from "moment";
import { CST_TIMEZONE } from "../../../Model/constants";
import _ from "lodash";

const PickATime = (props) => {
  const {
    activeStep,
    steps,
    handleReset,
    handleBack,
    isStepOptional,
    handleSkip,
    handleNext,
    videoVisitInfo,
    savePickATimeAction,
    pickATimeInfo,
    getAvailabilitySlots,
    slotAvailabilityInfo,
    isLoading,
    isError,
    queryParams,
  } = props;
  const [videoVisitInformation, setVideoVisitInfo] = useState({
    ...videoVisitInfo,
  });

  const [activeTimeSlot, setActiveTimeSlot] = useState("");
  const [activeSlotInfo, setActiveSlotInfo] = useState();
  const [options, setOptions] = useState({
    autoClose: false,
    keepAfterRouteChange: false,
  });

  // useEffect(() => {
  //   if (!_.isEmpty(pickATimeInfo)) {
  //     // setVideoVisitInfo(videoVisitInfo);
  //     const { date, time, doctor, slot } = pickATimeInfo;
  //     const id = `${date}-${doctor}-${slot}-${time}`;
  //     const item = document.getElementById(id);
  //     item?.classList?.add("activeTimeSlot");
  //     setActiveTimeSlot(id);
  //     let tmpVideoVisitInfo = { ...videoVisitInfo, when: date };
  //     setActiveSlotInfo({
  //       date: date,
  //       time: time,
  //       doctor: doctor,
  //       slot: slot,
  //     });
  //     setVideoVisitInfo(tmpVideoVisitInfo);
  //   }
  //   // }, []);
  // }, [videoVisitInfo, pickATimeInfo]);

  const fetchAvailabilityInfo = useCallback(
    async (param) => {
      if (param) {
        await getAvailabilitySlots(param);
      }
    },
    [videoVisitInformation.when]
  );

  useEffect(() => {
    (async () => {
      if (!queryParams) {
        const param = PickATimeModel.duration[videoVisitInformation.when];
        fetchAvailabilityInfo(param);
      }
    })();
  }, []);

  useLayoutEffect(() => {
    if (isLoading === false && isError && isError?.length) {
      const message = isError;
      alertService.error(message, {
        autoClose: true,
        keepAfterRouteChange: false,
      });
    }
  }, [isLoading, isError]);

  useEffect(() => {
    let tmpVideoVisitInfo = { ...videoVisitInfo };
    setVideoVisitInfo(tmpVideoVisitInfo);
  }, [videoVisitInfo]);

  const changeTab = (e) => {
    setVideoVisitInfo({ ...videoVisitInformation, when: e });
    const param = PickATimeModel.duration[e];
    fetchAvailabilityInfo(param);
    if (activeTimeSlot) {
      document
        .getElementById(activeTimeSlot)
        .classList.remove("activeTimeSlot");
      setActiveTimeSlot("");
    }
  };

  const handleNextOnPickATime = () => {
    if (activeSlotInfo) {
      const { date, time } = activeSlotInfo;
      const timeLag = moment().tz(CST_TIMEZONE).format();
      const CST_Date = PickATimeModel.tabLabel2[date];
      let d3 = moment1({
        year: CST_Date.split("/")[2],
        month: CST_Date.split("/")[1] - 1,
        day: CST_Date.split("/")[0],
        hour: time.split(":")[0],
        minute: time.split(":")[1],
        second: 0,
        millisecond: 0,
      });
      const appointmentStartTime = d3
        .format()
        .substring(0, 19)
        .concat(timeLag.substring(19));
      savePickATimeAction({ ...activeSlotInfo, appointmentStartTime });
      handleNext();
    }
  };

  const onTimeSlotClicked = (props) => {
    const { id } = props;
    const day = id.split("-").splice(0, 1).join("");
    const doctor = id.split("-")[1];
    const slot = id.split("-")[2];

    // const date =
    //   day === "today"
    //     ? moment().format("llll").split(",").splice(0, 2).join(",")
    //     : PickATimeModel.tabLabel[day];
    if (activeTimeSlot) {
      const prevItem = document.getElementById(activeTimeSlot);

      if (prevItem) {
        prevItem.classList.remove("activeTimeSlot");
      }
      // document.getElementById(id).classList.add("activeTimeSlot");

      const item = document.getElementById(id);
      if (item) {
        item.classList.add("activeTimeSlot");
        setActiveTimeSlot(id);
        setActiveSlotInfo({
          date: day,
          time: item.innerHTML,
          doctor: doctor,
          slot: slot,
          ...props,
        });
      }
    } else {
      const item = document.getElementById(id);
      if (item) {
        item.classList.add("activeTimeSlot");

        // console.log("e.target.avalue", item.innerHTML);
        setActiveTimeSlot(id);
        setActiveSlotInfo({
          date: day,
          time: item.innerHTML,
          doctor: doctor,
          slot: slot,
          ...props,
        });
      }
    }
  };

  const timezoneAbbreviation = moment().tz(CST_TIMEZONE).format("z");

  return (
    <div>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="card-shadow custom-card pick-time p-4 rounded-3">
            <h3 className="mb-5">
              Pick a Time
              <span
                style={{ color: "gray" }}
                className="card-shadow custom-card p-2 w-25 m-3  bg-light "
              >
                <AccessTimeOutlined style={{ color: "gray" }} />{" "}
                {timezoneAbbreviation}{" "}
              </span>{" "}
            </h3>

            {isLoading ? (
              <div className="d-flex h-100 justify-content-center ">
                <Loader
                  type="spinner-circle"
                  bgColor={"gray"}
                  // title={"spinner-circle"}
                  // color="black"
                  size={75}
                />
              </div>
            ) : (
              <Tabs
                defaultActiveKey={
                  PickATimeModel.pickATimeMap[videoVisitInformation.when] ||
                  PickATimeModel.pickATimeMap.today
                }
                activeKey={
                  PickATimeModel.pickATimeMap[videoVisitInformation.when] ||
                  PickATimeModel.pickATimeMap.today
                }
                transition={false}
                id="pick-a-time"
                className="mb-3 justify-content-end"
                onSelect={changeTab}
              >
                {slotAvailabilityInfo &&
                  Object.keys(pickATimeJSON).map((key) => (
                    <Tab
                      eventKey={key}
                      id={key}
                      title={
                        PickATimeModel.tabLabel[
                          PickATimeModel.pickATimeMap[key]
                        ]
                      }
                    >
                      {slotAvailabilityInfo[
                        PickATimeModel.pickATimeMap[key]
                      ] ? (
                        slotAvailabilityInfo[
                          PickATimeModel.pickATimeMap[key]
                        ].doctor.map((doctorInfo) => (
                          <Row className="justify-content-center">
                            <Col md={9} sm={12}>
                              <div className="py-2 mt-4">
                                <div className="doctor-name-img d-flex align-items-center">
                                  <div className="doctor-img">
                                    <img
                                      src="avatar-pic.svg"
                                      alt="doctor-profile-image"
                                    />
                                  </div>
                                  <p className="doctor-name ml-2 mb-0">
                                    {doctorInfo.name}
                                  </p>
                                </div>
                              </div>
                              <Row className="mt-3">
                                <Col xl={4} md={6} xs={6}>
                                  <p>
                                    <strong>Morning</strong>
                                  </p>
                                  <div
                                    className="time-list"
                                    id={`${key}-${doctorInfo.name}-Morning`}
                                  >
                                    <ul>
                                      {doctorInfo.morning.map(
                                        (morningSlots) => (
                                          <li>
                                            <div
                                              className="time-slot mb-1"
                                              id={`${key}-${doctorInfo.name}-Morning-${morningSlots}`}
                                              onClick={() => {
                                                const restInfo = _.omit(
                                                  doctorInfo,
                                                  [
                                                    "morning",
                                                    "evening",
                                                    "afternoon",
                                                  ]
                                                );
                                                onTimeSlotClicked({
                                                  id: `${key}-${doctorInfo.name}-Morning-${morningSlots}`,
                                                  ...restInfo,
                                                });
                                              }}
                                            >
                                              {morningSlots}
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </Col>
                                <Col xl={4} md={6} xs={6}>
                                  <p>
                                    <strong>Afternoon</strong>
                                  </p>
                                  <div
                                    className="time-list"
                                    id={`${key}-${doctorInfo.name}-Afternoon`}
                                  >
                                    <ul>
                                      {doctorInfo.afternoon.map(
                                        (afternoonSlots) => (
                                          <li>
                                            <div
                                              className="time-slot mb-1"
                                              id={`${key}-${doctorInfo.name}-Afternoon-${afternoonSlots}`}
                                              onClick={() => {
                                                const restInfo = _.omit(
                                                  doctorInfo,
                                                  [
                                                    "morning",
                                                    "evening",
                                                    "afternoon",
                                                  ]
                                                );
                                                onTimeSlotClicked({
                                                  id: `${key}-${doctorInfo.name}-Afternoon-${afternoonSlots}`,
                                                  ...restInfo,
                                                });
                                              }}
                                            >
                                              {afternoonSlots}
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </Col>
                                <Col xl={4} md={6} xs={6}>
                                  <p>
                                    <strong>Evening</strong>
                                  </p>
                                  <div
                                    className="time-list"
                                    id={`${key}-${doctorInfo.name}-Evening`}
                                  >
                                    <ul>
                                      {doctorInfo.evening.map(
                                        (eveningSlots) => (
                                          <li>
                                            <div
                                              className="time-slot mb-1"
                                              id={`${key}-${doctorInfo.name}-Evening-${eveningSlots}`}
                                              onClick={() => {
                                                const restInfo = _.omit(
                                                  doctorInfo,
                                                  [
                                                    "morning",
                                                    "evening",
                                                    "afternoon",
                                                  ]
                                                );
                                                onTimeSlotClicked({
                                                  id: `${key}-${doctorInfo.name}-Evening-${eveningSlots}`,
                                                  ...restInfo,
                                                });
                                              }}
                                            >
                                              {eveningSlots}
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ))
                      ) : (
                        <h3>No slots available</h3>
                      )}
                    </Tab>
                  ))}
              </Tabs>
            )}

            <Box sx={{ display: "flex", flexDirection: "row", pt: 4 }}>
              {/* <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button> */}
              <Box sx={{ flex: "1 1 auto" }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              <Button
                onClick={handleNextOnPickATime}
                disabled={!activeSlotInfo}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
            </Box>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PickATime;
