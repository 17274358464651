import { connect } from "react-redux";
import Profile from "./Profile";
import { saveProfileInformationAction } from "../../../store/actions";

const mapDispatchToProps = (dispatch) => ({
  saveProfileInformationAction: (info) =>
    dispatch(saveProfileInformationAction(info)),
});

const mapStateToProps = (state) => {
  return {
    videoVisitInfo: state.videoVisitInfo,
    // isLoading: state?.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
