import React, { useEffect, useState, useMemo, useCallback } from "react";
import Box from "@material-ui/core/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import moment from "moment-timezone";
import _ from "lodash";
import { confirmSuccess, confirmError } from "../../../Asset/images";
import { ConfirmationModel } from "../../../Model";
import { CST_TIMEZONE } from "../../../Model/constants";
import { alertService } from "../../../data/services";

const PaymentForm = (props) => {
  const { pickATimeInfo, history, paymentStatus, queryParams } = props;
  const { isSuccess, message, physician, scheduleStart } = paymentStatus;

  let dateTime = moment(scheduleStart).tz(CST_TIMEZONE).format("");
  const date = `${moment(dateTime).tz(CST_TIMEZONE).format("ddd")}, ${moment(
    dateTime
  )
    .tz(CST_TIMEZONE)
    .format("MMMM DD")
    .split(",")
    .splice(0, 2)
    .join(",")}`;

  const time = dateTime.split("T")[1];

  useEffect(() => {
    if (queryParams) {
      history.replace("/patient-wizard");
    }
  }, [queryParams]);

  const timezoneAbbreviation = moment().tz(CST_TIMEZONE).format("z");

  const onClickIntakeForm = (e) => {
    e.preventDefault();
    history.replace("/intake-form");
  };

  return (
    <React.Fragment>
      <div className="pick-time p-4 rounded-3">
        <div className="review-section d-flex flex-1 h-100 flex-column justify-content-center ">
          <Row className="justify-content-center">
            <Col xl={5} md={8} sm={12}>
              <div className="custom-card p-5 card-shadow confirmation">
                <img
                  src={isSuccess ? confirmSuccess : confirmError}
                  style={{ width: "3rem", height: "3rem" }}
                />
                <h2
                  className={
                    isSuccess ? "mb-1 text-green mt-3 " : "mb-1 text-red mt-3 "
                  }
                >
                  {isSuccess ? <>Appointment confirmed</> : <>{message}</>}
                </h2>
                <p className="mb-5">
                  {isSuccess ? (
                    <>
                      You will receive a confirmation by text in the next few
                      minutes.
                    </>
                  ) : (
                    <>Payment could not be processed on your card.</>
                  )}
                </p>
                {isSuccess && (
                  <>
                    <h1 className="mb-2">
                      {" "}
                      {!_.isEmpty(pickATimeInfo) && (
                        <div className="date_display">
                          {date}
                          {" at "}
                          {
                            ConfirmationModel.time[
                              parseInt(time?.split(":")[0])
                            ]
                          }
                          :{time?.split(":")?.splice(1, 1)?.join("")}{" "}
                          {ConfirmationModel.slotTime(
                            parseInt(time?.split(":")[0])
                          )}{" "}
                          {`${timezoneAbbreviation}`}
                        </div>
                      )}
                    </h1>
                    <h4 className="mb-4 date_display">with Dr. {physician}</h4>
                  </>
                )}
              </div>
            </Col>
          </Row>
          {isSuccess && (
            <Row className="justify-content-center">
              <Col xl={5} md={8} sm={12} className="mt-4">
                <div className="">
                  <h4 className="mb-1">Intake form</h4>
                  <p>Please have your ...... handy to complete the form.</p>
                  <button
                    className="btn-primary btn btn-sm"
                    onClick={onClickIntakeForm}
                  >
                    Start Intake Form
                  </button>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentForm;
