import { Children, cloneElement, useState, useEffect } from "react";

const AccordionList = ({ activeProfileCallback, children }) => {
  const [activeProfile, setActiveProfile] = useState(null);

  useEffect(() => {
    activeProfileCallback(activeProfile);
  }, [activeProfile, activeProfileCallback]);

  return (
    <div>
      {Children.map(children, (child, index) =>
        cloneElement(child, {
          activeProfile,
          setActiveProfile,
          index: index.toString(),
        })
      )}
    </div>
  );
};

export default AccordionList;
