import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { VideoVisitModel } from "../../../Model";
import { useHistory } from "react-router-dom";
import { TextField } from "utility-library-react16";

function UrgentCare(props) {
  const { saveVideoVisitAction, savePickATimeAction } = props;
  const [info, setInfo] = useState({ when: "", reason: "", zip: "" });
  const history = useHistory();

  const onChange = (type) => (e) => {
    if (type === VideoVisitModel.videoVisitZip.key)
      setInfo({ ...info, [type]: e });
    else setInfo({ ...info, [type]: e.target.value });
  };

  useEffect(() => {
    savePickATimeAction({});
  }, []);

  const isSubmitDisabled = () => {
    let isEmpty = false;
    isEmpty = Object.keys(info).some((item) => {
      if (info[item] === "") {
        return true;
      }
      if (item === VideoVisitModel.videoVisitZip.key) {
        return info[item].length < 5;
      }
      return false;
    });
    return isEmpty;
  };

  const saveVideoVisitInformation = () => {
    const isEmpty = isSubmitDisabled();
    if (!isEmpty) {
      saveVideoVisitAction(info);
      history.push("/patient-wizard");
    }
  };

  return (
    <React.Fragment>
      <div className="flex-1 h-100">
        <main className="main-container">
          <div className="content-area scrollbar h-100 d-flex flex-column">
            <div className="align-items-center">
              <h1
                className="text-center mb-5 mt-5"
                style={{ fontSize: "24px" }}
              >
                Video Visit
              </h1>
              <Container>
                <Row>
                  <Col className="searchbox card-shadow overflow-hidden">
                    <Row>
                      <Col md={3} sm={6} className="my-3">
                        <Form.Group>
                          <Form.Label className="mb-0">When?</Form.Label>
                          <Form.Select
                            className="form-control text-capitalize"
                            onChange={onChange("when")}
                          >
                            <option value="select">Select</option>
                            {VideoVisitModel.videoVisitWhen.map((item) => (
                              <option value={item} className="text-capitalize">
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={4} sm={6} className="border-left-right-1 my-3">
                        <Form.Group>
                          <Form.Label className="mb-0">
                            Reason for Visit?
                          </Form.Label>
                          <Form.Select
                            className="form-control text-capitalize"
                            onChange={onChange("reason")}
                          >
                            <option value="select">Select</option>
                            {VideoVisitModel.videoVisitReason.map((item) => (
                              <option value={item} className="text-capitalize">
                                {item}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col md={3} sm={6} className="my-3">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Label className="mb-0">Zip Code</Form.Label>

                          <TextField
                            placeholder="Enter your Zip Code"
                            defaultValue={
                              info[VideoVisitModel.videoVisitZip.key]
                            }
                            fieldKey={VideoVisitModel.videoVisitZip.key}
                            type="zip"
                            data={info}
                            setData={setInfo}
                            onChangeRetZip={onChange(
                              VideoVisitModel.videoVisitZip.key
                            )}
                            className="form-control"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2} sm={6} className="pr-0 pl-0 pl-sm-2">
                        <Button
                          className="h-100 w-100 rounded-0"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitDisabled()}
                          onClick={(e) => {
                            e.preventDefault();
                            saveVideoVisitInformation(info);
                          }}
                        >
                          Search
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
            <div
              className="footer nunito-regular text-center mt-auto mb-3"
              style={{ fontSize: "18px", color: "#7b7b7b" }}
            >
              A <span className="theos nunito-bold">Theos</span>
              <span className="health nunito-bold">Health</span> Partner
            </div>
          </div>
        </main>
      </div>
    </React.Fragment>
  );
}

export default UrgentCare;
