import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Name2 } from "../../Global/atoms/Name2";

function Step2({ step2, step2Info, setStep2Info, medication, setMedication }) {
  // const [step2Info, setStep2Info] = useState({ medication: [], yes: "" });
  // const [medication, setMedication] = useState(false);

  useEffect(() => {
    if (step2?.medication?.includes("Yes")) {
      setMedication(true);
    }
  }, [step2]);

  const handleClick = (e) => {
    const classList = document.getElementById(e.target.id).classList;
    const text = document.getElementById(e.target.id).innerHTML;
    if (classList.contains("active")) {
      classList.remove("active");
      let tmpMed = [];
      tmpMed = step2Info.medication.filter((item) => {
        if (text === "Yes") {
          setMedication(false);
        }
        tmpMed.push(item);
        return item !== text;
      });
      setStep2Info({ ...step2Info, medication: [...tmpMed] });
    } else {
      classList.add("active");
      if (text === "Yes") {
        setMedication(true);
      }

      setStep2Info({
        ...step2Info,
        medication: [
          ...step2Info.medication,
          document.getElementById(e.target.id).innerHTML,
        ],
      });
    }
  };

  const onChangeText = (info) => (e) => {
    setStep2Info({ ...step2Info, [info]: e });
  };

  const fetchClassList = (id) => {
    const text = document.querySelector(`#${id}`)?.innerHTML;
    if (step2Info.medication.includes(text)) {
      document.querySelector(`#${id}`)?.classList.add("active");
    }
  };

  return (
    <>
      <div className="intake-form-2">
        <Form.Label className="mb-1">
          <p className="pt-2">
            <strong>Are you currently taking any medication?</strong>
          </p>
        </Form.Label>
        <div
          id="no"
          className={`question-slot ${fetchClassList("no")}`}
          onClick={handleClick}
        >
          No
        </div>
        <div
          id="yes"
          className={`question-slot ${fetchClassList("yes")}`}
          onClick={handleClick}
        >
          Yes
        </div>
        <Form.Group
          id="medication_names"
          style={
            medication
              ? { opacity: 1, transition: "ease all 1s" }
              : { opacity: 0, transition: "ease all 1s" }
          }
        >
          <Form.Label className="text-uppercase">medication name(s)</Form.Label>
          {/* <Form.Control type="text" placeholder="Enter medication name(s)" onChange={onChangeText("yes")} /> */}
          <Name2
            value={step2Info.yes}
            placeholderText="Enter medication name(s)"
            keyfield="yes"
            onChange={onChangeText("yes")}
          />
        </Form.Group>
      </div>
    </>
  );
}

export default Step2;
