import { IS_LOADING } from "../actions/actionTypes";

const INITIAL_STATE = false;

export const isLoading = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case IS_LOADING:
      return action.isLoading;

    default:
      return state;
  }
};
