import React from "react";

const CurrentProfileContext = React.createContext(undefined);

function CurrentProfileProvider({ children, value }) {
  return (
    <CurrentProfileContext.Provider value={value}>
      {children}
    </CurrentProfileContext.Provider>
  );
}

function useCurrentProfileContext() {
  const context = React.useContext(CurrentProfileContext);
  if (context === undefined) {
    throw new Error(
      "useCurrentProfileContext must be used within a CurrentProfileProvider"
    );
  }
  return context;
}

export { CurrentProfileProvider, useCurrentProfileContext };
