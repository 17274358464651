import { PICKATIME, PROFILE, PICKATIME_INIT } from "../actions/actionTypes";

const INITIAL_STATE = {
  pickATime: {},
  profile: {}
};

export const patientWizardInfo = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PICKATIME:
      return {
        ...state,
        pickATime: { ...action.payload }
      };
    // return action.payload;
    case PROFILE:
      return {
        ...state,
        profile: { ...action.payload }
      };
    // return action.payload;
    case PICKATIME_INIT:
      return {
        pickATime: {},
        profile: {}
      };
    default:
      return state;
  }
};
