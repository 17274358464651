/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useCallback } from "react";
import Box from "@material-ui/core/Box";
import { Modal } from "@material-ui/core";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import {
  withPaymentProvider,
  CardElement,
  CardCVVElement,
  CardExpiryElement,
} from "reactjs-stripe-library";
import { FormGroup } from "react-bootstrap";
import { TextField, isNullOrEmptyField } from "utility-library-react16";
import Loader from "react-js-loader";
import axios from "axios";
import { PaymentModel, ProfileModel } from "../../../Model";
import { encryptToken, decryptData } from "./helper";
import { alertService } from "../../../data/services";

const PaymentForm = (props) => {
  const {
    isLoading,
    setPaymentStatus,
    queryParams,
    postInfo,
    setAppointmentStatus,
  } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const paymentIntentSecretfromQP = queryParams?.get(
    "payment_intent_client_secret"
  );

  const [userInfo, setUserInfo] = useState({
    nameOnCard: "",
    zip: "",
  });
  const [paymentInfo, setPaymentInfo] = useState({
    cardValid: null,
    expiryValid: null,
    cvcValid: null,
  });
  const [form, setForm] = useState({
    submitTriggered: false,
    error: false,
    paymentInitiated: false,
  });
  const [paymentIntentInfo, setPaymentIntentInfo] = useState();

  const saveCardInfoState = useCallback(
    (elem, isValid) => {
      setPaymentInfo({
        ...paymentInfo,
        [elem]: isValid,
      });
    },
    [paymentInfo]
  );

  const onCardElemChange =
    (elem) =>
    ({ error, complete, empty }) => {
      if (error) {
        saveCardInfoState(elem, false);
      }
      if (complete) {
        saveCardInfoState(elem, true);
      }
      if (empty) {
        saveCardInfoState(elem, null);
      }
    };

  const onChange = (type) => (e) => {
    setUserInfo({ ...userInfo, [type]: e });
  };

  const handleSubmit = () => {
    const isValid = !evaluateIsDisabled();

    if (isValid) {
      // console.log("initiate Payment");
      // history.push("/confirmation", { isSuccess: true });
      // handleNext();
      setForm({
        submitTriggered: true,
        error: !isValid,
        paymentInitiated: true,
      });
    } else {
      setForm({ submitTriggered: true, error: !isValid });
    }
  };

  const evaluateIsDisabled = () => {
    const { nameOnCard, zip } = userInfo;
    const { cardValid, cvcValid, expiryValid } = paymentInfo;
    let isValid = true;
    if (!nameOnCard) {
      isValid = false;
    }
    if (!zip) {
      isValid = false;
    }
    if (zip && zip.length < 5) {
      isValid = false;
    }
    if (!cardValid) {
      isValid = false;
    }
    if (!cvcValid) {
      isValid = false;
    }
    if (!expiryValid) {
      isValid = false;
    }

    return !isValid;
  };

  const getTokenCallBack = async (token) => {
    console.log("token => ", token);
    const { paymentMethod } = token;

    setIsProcessing(true);
    if (paymentMethod) {
      const { id } = paymentMethod;
      const encryptedPaymentMethod = encryptToken(id);
      const params = { ...postInfo, paymentMethodId: encryptedPaymentMethod };
      await axios
        .post(`${process.env.REACT_APP_API_URL}/payment/appointments`, {
          ...params,
        })
        .then(async (res) => {
          setIsProcessing(false);
          const { data } = res;
          const { paymentStatus } = data;
          if (paymentStatus === "succeeded") {
            const { physician, scheduleStart, patientId, id } = data;
            setAppointmentStatus({ patientId, appointmentId: id });
            setPaymentStatus({ isSuccess: true, physician, scheduleStart });
          }
        })
        .catch(async (err) => {
          setIsProcessing(false);
          const { response } = err;
          const { data } = response;
          const { clientSecrete, message } = data;
          if (clientSecrete) {
            setPaymentStatus({ isSuccess: false, message });
          } else {
            alertService.error(
              `${message}  ... you will be redirected to pick another slot`,
              {
                autoClose: false,
                keepAfterRouteChange: false,
              }
            );
            setTimeout(() => {
              window.location.reload();
            }, 7000);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <div className="card-shadow custom-card pick-time p-4 rounded-3">
        <h3 className="mb-5">Payment</h3>
        {isLoading || !props.decryptedKey ? (
          <div className="d-flex h-100 justify-content-center ">
            <Loader
              type="spinner-circle"
              bgColor={"gray"}
              // title={"spinner-circle"}
              // color="black"
              size={75}
            />
          </div>
        ) : (
          <div className="review-section">
            <Row className="justify-content-center">
              <Col md={6} sm={12} className="mt-4">
                <div className="">
                  <FormGroup className="mb-4">
                    <Row className="justify-content-center">
                      <Col md={10} sm={12}>
                        <h4 className="mb-3 pt-4">
                          Add your credit card information
                        </h4>
                        <Form className="profile-form">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1 text-uppercase">
                              Name on the card
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <TextField
                              type="name"
                              className="form-control"
                              placeholder="Enter name on card"
                              name="nameOnCard"
                              defaultValue={userInfo.nameOnCard}
                              value={userInfo.nameOnCard}
                              onChangeRetName={(value) => {
                                setUserInfo({
                                  ...userInfo,
                                  nameOnCard: value,
                                });
                              }}
                              // error={isNullOrEmptyField(info.self_last_name)}
                              style={
                                form.error &&
                                isNullOrEmptyField(userInfo.nameOnCard)
                                  ? ProfileModel.customStyle(form.error, {})
                                  : {}
                              }
                              disabled={form.paymentInitiated}
                            />
                          </Form.Group>

                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1 text-uppercase">
                              Card Number <span className="text-danger">*</span>
                            </Form.Label>

                            <CardElement
                              key="cardValid"
                              onCardElemChange={onCardElemChange("cardValid")}
                              error={
                                form.submitTriggered
                                  ? form.error && !paymentInfo.cardValid
                                  : paymentInfo.cardValid === false
                              }
                              primaryStyle={PaymentModel.primaryStyle}
                              errorStyle={PaymentModel.errorStyle}
                              showIcon
                              checkSecret={paymentIntentSecretfromQP}
                              form={{
                                ...form,
                                ...userInfo,
                                ...paymentIntentInfo,
                              }}
                              getTokenCallBack={getTokenCallBack}
                              disabled={form.paymentInitiated}
                            />
                          </Form.Group>

                          <Row>
                            <Col md={7} sm={12}>
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1 text-uppercase">
                                  Expiration Date (MM/YY)
                                  <span className="text-danger">*</span>
                                </Form.Label>

                                <CardExpiryElement
                                  key="expiryValid"
                                  onCardElemChange={onCardElemChange(
                                    "expiryValid"
                                  )}
                                  error={
                                    form.submitTriggered
                                      ? form.error && !paymentInfo.expiryValid
                                      : paymentInfo.expiryValid === false
                                  }
                                  primaryStyle={PaymentModel.primaryStyle}
                                  errorStyle={PaymentModel.errorStyle}
                                  disabled={form.paymentInitiated}
                                />
                              </Form.Group>
                            </Col>
                            <Col md={5} sm={12}>
                              <Form.Group className="mb-3">
                                <Form.Label className="mb-1 text-uppercase">
                                  CVV (3/4 digit code)
                                  <span className="text-danger">*</span>
                                </Form.Label>

                                <CardCVVElement
                                  key="cvcValid"
                                  onCardElemChange={onCardElemChange(
                                    "cvcValid"
                                  )}
                                  error={
                                    form.submitTriggered
                                      ? form.error && !paymentInfo.cvcValid
                                      : paymentInfo.cvcValid === false
                                  }
                                  primaryStyle={PaymentModel.primaryStyle}
                                  errorStyle={PaymentModel.errorStyle}
                                  disabled={form.paymentInitiated}
                                />
                              </Form.Group>
                            </Col>
                          </Row>

                          <Form.Group className="mb-3">
                            <Form.Label className="mb-1 text-uppercase">
                              Zip Code
                              <span className="text-danger">*</span>
                            </Form.Label>

                            <TextField
                              placeholder="Enter your Zip Code"
                              defaultValue={
                                userInfo[PaymentModel.paymentInfoZip.key]
                              }
                              fieldKey={PaymentModel.paymentInfoZip.key}
                              type="zip"
                              data={userInfo}
                              setData={setUserInfo}
                              onChangeRetZip={onChange(
                                PaymentModel.paymentInfoZip.key
                              )}
                              className="form-control"
                              style={
                                form.error &&
                                (isNullOrEmptyField(userInfo.zip) ||
                                  userInfo.zip.length < 5)
                                  ? ProfileModel.customStyle(form.error, {})
                                  : {}
                              }
                              disabled={form.paymentInitiated}
                            />
                          </Form.Group>
                        </Form>
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </div>
        )}

        <Box
          sx={{ display: "flex", flexDirection: "row-reverse", pt: 4 }}
          style={
            !props.decryptedKey
              ? {
                  transition: "all 1.0s",
                  opacity: 0,
                  transform: "translateY(0px)",
                }
              : {
                  transition: "all 1.0s",
                  opacity: 1,
                  transform: `translateY(-3rem)`,
                }
          }
        >
          <Button
            onClick={handleSubmit}
            disabled={evaluateIsDisabled() || form.paymentInitiated}
          >
            {!form.paymentInitiated ? (
              <>Submit</>
            ) : (
              <>
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>{" "}
                &nbsp; Processing...
              </>
            )}
          </Button>
        </Box>
      </div>
      <Modal
        open={paymentIntentSecretfromQP || isProcessing}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="d-flex justify-content-center payment_modal w-auto h-auto align-items-center  "
      >
        <>
          <div
            className="d-flex justify-content-center card-shadow custom-card pick-time p-4 rounded-3"
            style={{ height: "700px", width: "400px" }}
          >
            {/* <h3 className="mb-5">Payment</h3> */}
            <div className="d-flex h-100 justify-content-center ">
              <Loader
                type="spinner-circle"
                bgColor={"gray"}
                // title={"spinner-circle"}
                // color="black"
                size={75}
              />
            </div>
          </div>
        </>
      </Modal>
    </React.Fragment>
  );
};

const withPaymentProviderWrapper = (props) => {
  const { getPaymentGatewayKey, stripeKey, postInfo, zipPatWiz, queryParams } =
    props;

  const [key, setKey] = useState("");
  const { patient, attendee } = postInfo;

  const newPostInfo = {
    ...postInfo,
    patient: { ...patient, zipCode: zipPatWiz },
    attendee: { ...attendee, zipCode: zipPatWiz },
  };

  useEffect(() => {
    if (!queryParams) {
      getPaymentGatewayKey();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (stripeKey) {
        const res = await decryptData(stripeKey);

        setKey(res);
      }
    })();
  }, [stripeKey]);

  if (key) {
    return withPaymentProvider(PaymentForm, {
      ...props,
      key: `${key}`,
      decryptedKey: key,
      postInfo: newPostInfo,
    });
  }

  return <PaymentForm {...props} decryptedKey={null} />;
};

const Payment = (props) => withPaymentProviderWrapper(props);
export default Payment;
