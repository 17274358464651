import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { TextField } from "utility-library-react16";
import { ProfileModel } from "../../../Model";
import { useCurrentProfileContext } from "../context/useCurrentProfileContext";

export const Phone = (props) => {
  const { required, keyfield, placeholderText } = props;
  const [info, setInfo] = useState({ [keyfield]: "" });
  const {
    setData,
    error,
    nonMandateError,
    data,

    activeProfile,
  } = useCurrentProfileContext();

  useEffect(() => {
    if (!activeProfile) {
      setTimeout(() => {
        setInfo({
          [keyfield]: "",
        });
      }, 250);
    }
  }, [activeProfile]);

  useEffect(() => {
    setData({ ...data, ...info });
  }, [info]);

  return (
    <>
      <Form.Group
        className="mb-3"
        key={`form-elem-${activeProfile}-${keyfield}`}
      >
        <Form.Label className="mb-1 text-uppercase">
          phone number {required && <span className="text-danger">*</span>}
        </Form.Label>

        <TextField
          id={`${activeProfile}-${keyfield}`}
          type="tel"
          className={
            (error || nonMandateError) && info[keyfield].length < 13
              ? "errorControl form-control"
              : "form-control"
          }
          placeholder={placeholderText || "(999)999-9999"}
          defaultValue={info[keyfield]}
          fieldKey={keyfield}
          data={info}
          setData={setInfo}
          onChangeUSTel={(value) => {
            setInfo({ ...info, [keyfield]: value });
          }}
          // error={isNullOrEmptyField(info.self_phone)}
          style={
            (error || nonMandateError) && info[keyfield].length < 13
              ? ProfileModel.customStyle(error || nonMandateError, {})
              : {}
          }
        />
      </Form.Group>
    </>
  );
};
