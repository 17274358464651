import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { FormGroup } from "react-bootstrap";
import _ from "lodash";
import moment from "moment-timezone";
import { CST_TIMEZONE } from "../../../Model/constants";
import { ReviewAppointmentModel } from "../../../Model";

const PatientWizard = (props) => {
  const {
    // steps,
    // activeStep,
    // handleReset,
    // handleBack,
    // isStepOptional,
    // handleSkip,
    handleNext,
    pickATimeInfo,
  } = props;
  const [terms, setTerms] = useState();

  const { date, time, doctor, slot, lastName, firstName } = pickATimeInfo;

  const timezoneAbbreviation = moment().tz(CST_TIMEZONE).format("z");

  return (
    <React.Fragment>
      <div className="card-shadow custom-card pick-time p-4 rounded-3">
        <h3 className="mb-5">Review</h3>

        <div className="review-section">
          <Row className="justify-content-center">
            <Col md={10} sm={12}>
              <div className="dark-bg p-4 card-shadow">
                <h4 className="mb-1">Appointment</h4>
                <h4 className="text-primary mb-4 date_display">
                  {/* Monday, Feb. 12 at 11:00 am with Dr. Smith, Janeth */}
                  {!_.isEmpty(pickATimeInfo) && (
                    <>
                      {ReviewAppointmentModel.tabLabel[date]}
                      {" at "}
                      {
                        ReviewAppointmentModel.time[
                          time.split(":").splice(0, 1).join("")
                        ]
                      }
                      :{time.split(":").splice(1, 1).join("")}
                      {ReviewAppointmentModel.slotAMPM[slot]}{" "}
                      {`${timezoneAbbreviation}`} with Dr. {lastName},{" "}
                      {firstName}
                    </>
                  )}
                </h4>
                <p className="mb-0">
                  You will receive a clickable link to the video appointment 15
                  min before your scheduled visit.
                </p>
              </div>
            </Col>
            <Col md={10} sm={12} className="mt-4">
              <div className="dark-bg p-4 card-shadow">
                <h4 className="mb-1">Services</h4>
                <h4 className="text-primary mb-4">Virtual doctor visit</h4>
                <div className="d-flex justify-content-between">
                  <p className="mb-0">Total:</p>
                  <h5 className="mb-0">$ 35</h5>
                </div>
              </div>
            </Col>
            <Col md={10} sm={12} className="mt-4">
              <div className="">
                <h4 className="mb-3 pt-4">Please agree with the terms below</h4>
                <FormGroup className="mb-4">
                  <Form.Check
                    type="checkbox"
                    label="The fee covers my visit with the provider only If I choose to pick up my medication at a pharmacy, I understand that the consultation fee does not cover the cost of medication and I still have to pay at the pharmacy."
                    id="review-checkbox"
                    onClick={(e) => setTerms(e.target.checked)}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
        </div>

        <Box sx={{ display: "flex", flexDirection: "row-reverse", pt: 4 }}>
          <Button onClick={handleNext} disabled={!terms}>
            Go To Payment
          </Button>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default PatientWizard;
