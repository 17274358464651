import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import { FormGroup } from "react-bootstrap";
import MobileStepper from "@material-ui/core/MobileStepper";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { IntakeFormModel } from "../../../Model";

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
});

const IntakeFormWithSuccess = (props) => {
  const {
    saveStep1Action,
    saveStep2Action,
    saveStep3Action,
    step1,
    step2,
    step3,
    appointmentInfo,
  } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const [step1Info, setStep1Info] = useState({ medicalCondition: [] });
  const [step2Info, setStep2Info] = useState({ medication: [], yes: "" });
  const [medication, setMedication] = useState(false);
  const [step3Info, setStep3Info] = useState({
    allergy: [],
    medicationAllergy: "",
    otherAllergy: "",
  });
  const [medicationAllergy, setMedicationAllergy] = useState(false);
  const [otherAllergy, setOtherAllergy] = useState(false);

  const handleSaveIntakeForm = useCallback(() => {
    const { appointmentId = null, patientId = null } = appointmentInfo;
    IntakeFormModel.constructRequestParams({
      step1,
      step2,
      step3,
      appointmentId,
      patientId,
    });
  }, [step1, step2, step3]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 1) {
        saveStep1Action({ ...step1Info });
      }
      if (prevActiveStep === 2) {
        saveStep2Action({ ...step2Info });
      }
      if (prevActiveStep === 3) {
        saveStep3Action({ ...step3Info });
        return 3;
      }
      return prevActiveStep + 1;
    });
  };

  useEffect(() => {
    if (step1) {
      setStep1Info({ ...step1 });
    }
    if (step2) {
      setStep2Info({ ...step2 });
    }
    if (step3) {
      setStep3Info({ ...step3 });
      handleSaveIntakeForm();
    }
  }, [step1, step2, step3]);

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);

    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 1) {
        saveStep1Action({ ...step1Info });
        return 1;
      }
      if (prevActiveStep === 2) {
        saveStep2Action({ ...step2Info });
      }
      if (prevActiveStep === 3) {
        saveStep3Action({ ...step3Info });
      }
      return prevActiveStep - 1;
    });
  };

  return (
    <React.Fragment>
      <div className="flex-1 vh-100" style={{ overflowY: "scroll" }}>
        <main
          className="main-container d-flex flex-column justify-content-between  "
          style={{ overflowY: "scroll", overflowX: "hidden" }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} className="mt-10 vh-10" />

            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1} justifyContent="center">
                <Grid item xs={11} sm={11} md={8}>
                  <div className="d-flex justify-content-center">
                    <Col
                      md={10}
                      xs={11}
                      className="card-shadow custom-card pick-time p-4 rounded-3 mt-5"
                    >
                      <h3 className="mb-5">Intake Form</h3>

                      <div className="review-section">
                        <Row className="justify-content-center">
                          <Col lg={9} md={12} sm={12} className="mt-4">
                            <div className="">
                              <FormGroup className="mb-4">
                                <Row className="justify-content-center">
                                  <Col lg={10} md={12} xs={11}>
                                    <Form className="intake-form profile-form">
                                      <Form.Group className="mb-3">
                                        <MobileStepper
                                          variant="progress"
                                          steps={4}
                                          position="static"
                                          activeStep={activeStep}
                                          className={classes.root}
                                        />
                                      </Form.Group>
                                      <div style={{ height: "504px" }}>
                                        {activeStep === 1 && (
                                          <Step1
                                            step1={step1}
                                            step1Info={step1Info}
                                            setStep1Info={setStep1Info}
                                          />
                                        )}
                                        {activeStep === 2 && (
                                          <Step2
                                            step2={step2}
                                            step2Info={step2Info}
                                            setStep2Info={setStep2Info}
                                            medication={medication}
                                            setMedication={setMedication}
                                          />
                                        )}
                                        {activeStep === 3 && (
                                          <Step3
                                            step3={step3}
                                            step3Info={step3Info}
                                            setStep3Info={setStep3Info}
                                            medicationAllergy={
                                              medicationAllergy
                                            }
                                            setMedicationAllergy={
                                              setMedicationAllergy
                                            }
                                            otherAllergy={otherAllergy}
                                            setOtherAllergy={setOtherAllergy}
                                          />
                                        )}
                                      </div>
                                    </Form>
                                  </Col>
                                </Row>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <Box className="d-flex justify-content-end ">
                        <Button
                          type="button"
                          className="mr-4"
                          variant="outline-primary"
                          onClick={handleBack}
                          disabled={activeStep === 1}
                        >
                          Previous
                        </Button>
                        <Button
                          onClick={handleNext}
                          // disabled={activeStep === 3}
                        >
                          {activeStep === 3 ? <>Submit</> : <>Next</>}
                        </Button>
                      </Box>
                    </Col>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} className="mt-10 vh-10" />
          </Grid>

          <div
            className="footer nunito-regular text-center mt-5 mb-3"
            style={{ fontSize: "18px", color: "#7b7b7b" }}
          >
            A <span className="theos nunito-bold">Theos</span>
            <span className="health nunito-bold">Health</span> Partner
          </div>
        </main>
      </div>
    </React.Fragment>
  );
};

const WithPaymentInfo = (props) => {
  const { paymentStatus } = props;
  const history = useHistory();
  if (_.isNull(paymentStatus) || !paymentStatus?.isSuccess) {
    history.replace("/patient-wizard");
  }

  return <IntakeFormWithSuccess {...props} />;
};
const IntakeForm = (props) => WithPaymentInfo(props);
export default IntakeForm;
