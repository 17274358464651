import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { isNullOrEmptyField } from "utility-library-react16";
import DatePicker from "react-datepicker";
import { useCurrentProfileContext } from "../context/useCurrentProfileContext";

export const DOB = (props) => {
  const { required, keyfield, placeholderText } = props;
  const [info, setInfo] = useState({ [keyfield]: "" });
  const {
    setData,
    error,
    data,
    activeProfile,
  } = useCurrentProfileContext();

  useEffect(() => {
    if (!activeProfile) {
      setTimeout(() => {
        setInfo({
          [keyfield]: "",
        });
      }, 250);
    }
  }, [activeProfile]);

  useEffect(() => {
    setData({ ...data, ...info });
  }, [info]);

  return (
    <>
      <Form.Group className="">
        <Form.Label className="mb-1 text-uppercase">
          date of birth (mm/dd/yyyy){" "}
          {required && <span className="text-danger">*</span>}
        </Form.Label>
      </Form.Group>

      <DatePicker
        placeholderText={placeholderText || "Enter date of birth"}
        id="dob"
        selected={info[keyfield]}
        // dateFormat="MMMM d, yyyy"
        isClearable={error && isNullOrEmptyField(info[keyfield]) ? false : true}
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={150}
        shouldCloseOnSelect={true}
        maxDate={new Date()}
        onChange={(date) => {
          setInfo({ ...info, [keyfield]: date });
        }}
        className={
          error && isNullOrEmptyField(info[keyfield])
            ? "errorControl form-control mb-3"
            : "form-control mb-3"
        }
        required
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    </>
  );
};
