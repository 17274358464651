/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useHistory, useLocation } from "react-router-dom";
import { TextField } from "utility-library-react16";
import { VideoVisitModel } from "../../../Model";
import PickATime from "../PickATime";
import Profile from "../Profile";
import ReviewAppointment from "../ReviewAppointment";
import Payment from "../Payment";
import _ from "lodash";
import Confirmation from "../Confirmation";

const steps = ["Pick a Time", "Profile", /*"Pharmacy",*/ "Review", "Payment"];

const PatientWizardForm = (props) => {
  const { videoVisitInfo, saveVideoVisitAction, paymentStatus, queryParams } =
    props;
  const [videoVisitInformation, setVideoVisitInfo] = useState({
    ...videoVisitInfo,
  });
  const [activeStep, setActiveStep] = React.useState(null);
  const [skipped, setSkipped] = React.useState(new Set());
  const [qParam, setQParams] = useState();

  useEffect(() => {
    if (queryParams) {
      setActiveStep(3);
      setQParams(queryParams);
    }
    if (!queryParams && !paymentStatus) {
      setActiveStep(0);
    }
  }, [queryParams, paymentStatus]);

  useEffect(() => {
    setVideoVisitInfo(videoVisitInfo);
  }, [videoVisitInfo]);

  const onChange = (type) => (e) => {
    if (type === VideoVisitModel.videoVisitZip.key)
      setVideoVisitInfo({ ...videoVisitInformation, [type]: e });
    else
      setVideoVisitInfo({ ...videoVisitInformation, [type]: e.target.value });
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    saveVideoVisitAction({ ...videoVisitInformation });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <React.Fragment>
      {!_.isNull(paymentStatus) ? (
        <Confirmation {...props} paymentStatus={paymentStatus} />
      ) : (
        <div className="flex-1 h-100">
          <main className="main-container">
            <div className="content-area scrollbar h-100 d-flex flex-column">
              <div className="align-items-center">
                <Container>
                  <Row className="justify-content-center">
                    <Col
                      md={8}
                      className="searchbox card-shadow overflow-hidden mb-5"
                    >
                      <Row>
                        <Col md={6} sm={6} className="border-right-1 my-3">
                          <Form.Group>
                            <Form.Label className="mb-0">
                              Reason for Visit?
                            </Form.Label>
                            <Form.Select
                              value={videoVisitInformation.reason}
                              className="form-control text-capitalize"
                              onChange={onChange("reason")}
                            >
                              <option value="select">Select</option>
                              {VideoVisitModel.videoVisitReason.map((item) => (
                                <option
                                  value={item}
                                  className="text-capitalize"
                                >
                                  {item}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col md={4} sm={6} className="my-3">
                          <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label className="mb-0">Zip Code</Form.Label>

                            <TextField
                              placeholder="Enter your Zip Code"
                              defaultValue={
                                videoVisitInformation[
                                  VideoVisitModel.videoVisitZip.key
                                ]
                              }
                              fieldKey={VideoVisitModel.videoVisitZip.key}
                              type="zip"
                              data={videoVisitInformation}
                              setData={setVideoVisitInfo}
                              onChangeRetZip={onChange(
                                VideoVisitModel.videoVisitZip.key
                              )}
                              className="form-control"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={2} sm={6} className="pr-0 pl-0 pl-sm-2">
                          <Button
                            className="h-100 w-100 rounded-0"
                            variant="primary"
                            type="submit"
                          >
                            Search
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
                <Container className="custom-stepper">
                  <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={activeStep}>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepOptional(index)) {
                        }
                        if (isStepSkipped(index)) {
                          stepProps.completed = false;
                        }
                        return (
                          <Step
                            key={label}
                            {...stepProps}
                            style={
                              index < 4
                                ? { width: "100%", paddingRight: "10px" }
                                : { width: "100%" }
                            }
                          >
                            <StepLabel {...labelProps}>{label}</StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                    {activeStep === 0 && (
                      <PickATime
                        steps={steps}
                        activeStep={activeStep}
                        handleReset={handleReset}
                        handleBack={handleBack}
                        isStepOptional={isStepOptional}
                        handleSkip={handleSkip}
                        handleNext={handleNext}
                        queryParams={qParam}
                      />
                    )}
                    {activeStep === 1 && (
                      <Profile
                        steps={steps}
                        activeStep={activeStep}
                        handleReset={handleReset}
                        handleBack={handleBack}
                        isStepOptional={isStepOptional}
                        handleSkip={handleSkip}
                        handleNext={handleNext}
                      />
                    )}
                    {activeStep === 2 && (
                      <ReviewAppointment
                        steps={steps}
                        activeStep={activeStep}
                        handleReset={handleReset}
                        handleBack={handleBack}
                        isStepOptional={isStepOptional}
                        handleSkip={handleSkip}
                        handleNext={handleNext}
                      />
                    )}
                    {activeStep === 3 && (
                      <Payment
                        steps={steps}
                        activeStep={activeStep}
                        handleReset={handleReset}
                        handleBack={handleBack}
                        isStepOptional={isStepOptional}
                        handleSkip={handleSkip}
                        handleNext={handleNext}
                        queryParams={qParam}
                      />
                    )}
                  </Box>
                </Container>
              </div>
              <div
                className="footer nunito-regular text-center mt-auto mb-3"
                style={{ fontSize: "18px", color: "#7b7b7b" }}
              >
                A <span className="theos nunito-bold">Theos</span>
                <span className="health nunito-bold">Health</span> Partner
              </div>
            </div>
          </main>
        </div>
      )}
    </React.Fragment>
  );
};

const withVideoVisitInfo = (props) => {
  const location = useLocation();

  const { search } = location;
  let queryParams;
  if (search) {
    queryParams = new URLSearchParams(search);
  }

  if (_.isEmpty(props.videoVisitInfo)) {
    const history = useHistory();
    history.push("/");
  }

  return <PatientWizardForm {...props} queryParams={queryParams} />;
};

const PatientWizard = (props) => withVideoVisitInfo(props);
export default PatientWizard;
