import {
  INTAKE_FORM_STEP1,
  INTAKE_FORM_STEP2,
  INTAKE_FORM_STEP3,
  INTAKE_FORM_INIT,
} from "../actions/actionTypes";

const STEP1_STATE = {
  step1: null,
  step2: null,
  step3: null,
};

export const intakeFormInfo = (state = STEP1_STATE, action) => {
  switch (action.type) {
    case INTAKE_FORM_STEP1:
      return { ...state, step1: action.payload };
    case INTAKE_FORM_STEP2:
      return { ...state, step2: action.payload };
    case INTAKE_FORM_STEP3:
      return { ...state, step3: action.payload };
    case INTAKE_FORM_INIT:
      return { step1: null, step2: null, step3: null };
    default:
      return state;
  }
};
