import React from "react";
import ReactDOM from "react-dom";
import Progress from "react-progress-2";
import "./index.css";
import App from "./App";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import { history } from "./Providers/History";
import { store, persistor } from "./store/store";
import "bootstrap";
import "./Asset/styles/css/web-icons.css";
import "./App.scss";
import "react-progress-2/main.css";
import "../src/data/services/interceptorService.js";
 
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <Progress.Component />
          <App />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
