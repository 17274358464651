import moment from "moment-timezone";
import { CST_TIMEZONE } from "./constants";

const tabLabel = {
  today: `${moment().tz(CST_TIMEZONE).format("ddd")}, ${moment()
    .tz(CST_TIMEZONE)
    .format("MMMM DD")
    .split(",")
    .splice(0, 2)
    .join(",")}`,
  tomorrow: `${moment()
    .tz(CST_TIMEZONE)
    .add(1, "days")
    .format("ddd")}, ${moment()
    .tz(CST_TIMEZONE)
    .add(1, "days")
    .format("MMMM DD")
    .split(",")
    .splice(0, 2)
    .join(",")}`,
  dayAfterTomorrow: `${moment()
    .tz(CST_TIMEZONE)
    .add(2, "days")
    .format("ddd")}, ${moment()
    .tz(CST_TIMEZONE)
    .add(2, "days")
    .format("MMMM DD")
    .split(",")
    .splice(0, 2)
    .join(",")}`,
};

const slotAMPM = {
  Morning: "am",
  Afternoon: "pm",
  Evening: "pm",
};

const time = {
  8: "8",
  9: "9",
  10: "10",
  11: "11",
  12: "12",
  13: "01",
  14: "02",
  15: "03",
  16: "04",
  17: "05",
  18: "06",
  19: "07",
  20: "08",
};

const slotTime = (hour) => {
  if (hour < 12) {
    return "am";
  }
  return "pm";
};

export const ConfirmationModel = {
  tabLabel,
  slotAMPM,
  time,
  slotTime,
};
