import React from "react";
// import Amplify from 'aws-amplify';
// import awsconfig from './aws-exports';
// import {Authenticator} from "aws-amplify-react";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { withRouter } from "react-router-dom";
// import AuthWrapper from "./Components/Auth/AuthWrapper";
import Header from "./Containers/PageContainer/Layout/Header";
import Footer from "./Components/Layout/Footer";
import { Alert } from "./Components/Global/Alert/Alert";
// import {Auth, Hub} from 'aws-amplify';
import { Route } from "react-router-dom";
import UrgentCare from "./Components/PageContainer/UrgentCare";
import PatientWizard from "./Components/PageContainer/PatientWizard";
import Confirmation from "./Components/PageContainer/Confirmation";
import IntakeForm from "./Components/PageContainer/IntakeForm";

// Amplify.configure(awsconfig);

function App() {
  return (
    <div className="App app-wrapper h-100">
      <React.Fragment>
        <Alert></Alert>
        <Header />
        {/* <Authenticator hideDefault={true} amplifyConfig={awsconfig}> */}
        {/* <div className="flex-1 h-100"> */}
        <Route exact path="/" component={UrgentCare} />
        <Route exact path="/patient-wizard" component={PatientWizard} />
        <Route exact path="/confirmation" component={Confirmation} />
        <Route exact path="/intake-form" component={IntakeForm} />
        {/* </div> */}
        {/* </Authenticator> */}

        <Footer />
      </React.Fragment>
    </div>
  );
}

export default withRouter(App);
