import React, { useCallback } from "react";
import Form from "react-bootstrap/Form";
import { Name2 } from "../../Global/atoms/Name2";
import { useEffect } from "react";
import _ from "lodash";

function Step3({
  step3,
  step3Info,
  setStep3Info,
  medicationAllergy,
  setMedicationAllergy,
  otherAllergy,
  setOtherAllergy,
}) {
  useEffect(() => {
    if (step3?.allergy?.includes("Medication")) {
      setMedicationAllergy(true);
    }
    if (step3?.allergy?.includes("Other")) {
      setOtherAllergy(true);
    }
  }, [step3]);

  const handleClick = (e) => {
    const classList = document.getElementById(e.target.id).classList;
    const text = document.getElementById(e.target.id).innerHTML;
    if (classList.contains("active")) {
      classList.remove("active");
      let tmpallergy = [];

      tmpallergy = step3Info.allergy.filter((item) => {
        if (text === "Medication") {
          setMedicationAllergy(false);
        }
        if (text === "Other") {
          setOtherAllergy(false);
        }
        tmpallergy.push(item);
        return item !== text;
      });

      setStep3Info({ ...step3Info, allergy: [...tmpallergy] });
    } else {
      classList.add("active");
      if (text === "Medication") {
        setMedicationAllergy(true);
      }
      if (text === "Other") {
        setOtherAllergy(true);
      }
      setStep3Info({
        ...step3Info,
        allergy: [
          ...step3Info.allergy,
          document.getElementById(e.target.id).innerHTML,
        ],
      });
    }
  };

  const onChangeText = useCallback(
    (info) => (e) => {
      setStep3Info({ ...step3Info, [info]: e });
    },
    [step3Info]
  );

  const fetchClassList = useCallback(
    (id) => {
      const text = document.querySelector(`#${id}`)?.innerHTML;
      if (step3Info.allergy.includes(text)) {
        document.querySelector(`#${id}`)?.classList.add("active");
      }
    },
    [step3Info.allergy]
  );

  return (
    <>
      <div className="intake-form-3">
        <Form.Label className="mb-1">
          <p className="pt-2">
            <strong>Do you have any of the following allergy?</strong>
          </p>
        </Form.Label>
        <div
          id="none"
          className={`question-slot ${fetchClassList("none")}`}
          onClick={handleClick}
        >
          None
        </div>
        <div
          id="medications"
          className={`question-slot ${fetchClassList("medications")}`}
          onClick={handleClick}
        >
          Medication
        </div>
        <Form.Group
          id="medication_name"
          className="mb-4"
          style={
            medicationAllergy
              ? {
                  opacity: 1,
                  transition: "ease all 1s",
                  transitionDelay: "0.7s",
                }
              : {
                  opacity: 0,
                  transition: "ease all 1s",
                  transform: "translateX(-3rem)",
                }
          }
        >
          <Form.Label className="text-uppercase">medication name(s)</Form.Label>
          {/* <Form.Control type="text" placeholder="Enter medication name(s)" disabled={!medication} onChange={onChangeText("medication")} /> */}
          <Name2
            value={step3Info.medicationAllergy}
            placeholderText="Enter medication name(s)"
            keyfield="medicationAllergy"
            onChange={onChangeText("medicationAllergy")}
            disabled={!medicationAllergy}
          />
        </Form.Group>
        <div
          style={
            medicationAllergy
              ? {
                  transform: "translateY(0rem)",
                  transition: "ease all 1s",
                }
              : {
                  transition: "ease all 1s",
                  transform: "translateY(-5rem)",
                  transitionDelay: "0.7s",
                }
          }
        >
          <div
            id="food"
            className={`question-slot ${fetchClassList("food")}`}
            onClick={handleClick}
          >
            Food
          </div>
          <div
            id="insect"
            className={`question-slot ${fetchClassList("insect")}`}
            onClick={handleClick}
          >
            Insect
          </div>
          <div
            id="otherAllergy"
            className={`question-slot ${fetchClassList("otherAllergy")}`}
            onClick={handleClick}
          >
            Other
          </div>
          <Form.Group
            id="otherAllergy"
            style={
              otherAllergy
                ? { opacity: 1, transition: "ease all 1s" }
                : {
                    opacity: 0,
                    transition: "ease all 1s",
                    transform: "translateX(-3rem)",
                  }
            }
          >
            <Form.Label className="text-uppercase">Please specify</Form.Label>
            {/* <Form.Control
              type="text"
              placeholder="Enter your allergies"
              disabled={!otherAllergy}
              onChange={onChangeText("otherAllergy")}
            /> */}
            <Name2
              value={step3Info.otherAllergy}
              placeholderText="Enter your allergies"
              keyfield="otherAllergy"
              onChange={onChangeText("otherAllergy")}
              disabled={!otherAllergy}
            />
          </Form.Group>
        </div>
      </div>
    </>
  );
}

export default Step3;
