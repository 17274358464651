import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { TextField, isValidEmail } from "utility-library-react16";
import _ from "lodash";
import { ProfileModel } from "../../../Model";
import { useCurrentProfileContext } from "../context/useCurrentProfileContext";

export const Email = (props) => {
  const { required, keyfield, placeholderText } = props;
  const [info, setInfo] = useState({ [keyfield]: "" });
  const {
    setData,
    error,
    nonMandateError,
    data,
    activeProfile,
  } = useCurrentProfileContext();

  useEffect(() => {
    if (!activeProfile) {
      setTimeout(() => {
        setInfo({
          [keyfield]: "",
        });
      }, 250);
    }
  }, [activeProfile]);

  useEffect(() => {
    setData({ ...data, ...info });
  }, [info]);

  return (
    <>
      <Form.Group
        className="mb-3"
        key={`form-elem-${activeProfile}-${keyfield}`}
      >
        <Form.Label className="mb-1 text-uppercase">
          email address {required && <span className="text-danger">*</span>}
        </Form.Label>
        <TextField
          id={`${activeProfile}-${keyfield}`}
          className={
            nonMandateError &&
              !isValidEmail({ str: info[keyfield], isNullable: true })
              ? "errorControl form-control"
              : "form-control"
          }
          placeholder={placeholderText || "Enter your email address"}
          defaultValue={info[keyfield]}
          fieldKey={keyfield}
          type="email"
          data={info}
          setData={setInfo}
          style={
            nonMandateError &&
              !isValidEmail({ str: info[keyfield], isNullable: true })
              ? ProfileModel.customStyle(nonMandateError, {})
              : {}
          }
        />
      </Form.Group>
    </>
  );
};
