import CryptoJS from "crypto-js";

export const encryptToken = (tok) => {
  const encSecretKeys = CryptoJS.SHA256(process.env.REACT_APP_ENC_SECRET_KEY)
    .toString(CryptoJS.enc.Base64)
    .substring(0, 32);
  let key = CryptoJS.enc.Utf8.parse(encSecretKeys);
  let iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_SECRET_IV);
  var encryptedCP = CryptoJS.AES.encrypt(tok, key, { iv: iv });
  var encryptedBase64 = encryptedCP.toString();
  return encryptedBase64;
};

export const decryptData = async (encryptedData) => {
  const encSecretKeys = CryptoJS.SHA256(process.env.REACT_APP_ENC_SECRET_KEY)
    .toString(CryptoJS.enc.Base64)
    .substring(0, 32);
  let key = CryptoJS.enc.Utf8.parse(encSecretKeys);
  let iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENC_SECRET_IV);
  let decryptedWA = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv });

  let decryptedUtf8 = decryptedWA.toString(CryptoJS.enc.Utf8);
  return decryptedUtf8;
};
